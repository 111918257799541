import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '../presentation-sharing/presentation-sharing.interfaces';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnChanges {
  @Input() users: any[] = [];
  @Input() showRemoveButton = false;
  @Input() showInviteInfo = false;
  @Input() isAutocomplete = false;
  @Input() selectedIndex = -1;
  @Input() listPositionTop: number;
  @Input() listPositionLeft: number;
  @Input() listWidth: number;

  @Output() removeUser = new EventEmitter<string>();
  @Output() selectUser = new EventEmitter<User>();
  @Output() roleChange = new EventEmitter<User>();

  selectedRoleIds: { [email: string]: number } = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users?.currentValue) {
      this.users.forEach(user => {
        const selectedCategory = user.roleCategory?.find(cat => cat.selected);

        if (selectedCategory) {
          this.selectedRoleIds[user.email] = selectedCategory.name;
        }
      });
    }
  }

  onRemoveUser(email: string): void {
    this.removeUser.emit(email);
  }

  onSelectUser(user: User): void {
    this.selectUser.emit(user);
  }

  onRoleChange(user: User, name: string): void {
    if (user.roleCategory) {
      user.roleCategory = user.roleCategory.map(category => ({
        ...category,
        selected: category.name === name,
      }));
    }

    this.roleChange.emit(user);
  }
}
