<ac-modal-header (close)="close()">
  <p class="title m-0">Create Custom Page Copy</p>
</ac-modal-header>

<ac-modal-body>
  <div class="custom-page-name d-flex flex-row align-items-center justify-content-between py-4">
    <label>Custom Page Name</label>
    <se-form-input type="text" [formControl]="formValue"></se-form-input>
  </div>
  <div class="d-flex flex-column align-items-center justify-content-between py-4">
    <div class="d-flex w-100 justify-content-between py-3">
      <label>Reuse inserts instead of duplication</label>
      <se-checkbox [checked]="isInsertReuseEnabled" (onToggle)="onInsertReuseToggle()"></se-checkbox>
    </div>
    <div class="d-flex w-100 justify-content-between py-3">
      <label>Copy and link all dependent pages</label>
      <se-checkbox [checked]="isDependentPagesLinked" (onToggle)="onDependentPagesLinkToggle()"></se-checkbox>
    </div>
  </div>
</ac-modal-body>

<ac-modal-footer>
  <div class="container-btn d-flex justify-content-center align-items-center">
    <se-button
      class="btn-copy d-block"
      type="submit"
      [class]="isLoading ? 'secondary' : 'main'"
      [loading]="isLoading"
      [disabled]="!formValue.valid"
      (click)="onCopyCustomPage()"
    >
      Copy
    </se-button>
    <se-button class="secondary" (click)="close()">Cancel</se-button>
  </div>
</ac-modal-footer>
