import { Inject, Injectable } from '@angular/core';

import { find, min, max, union, map, isNil } from 'lodash-es';
import { Store, select } from '@ngrx/store';
import { map as maprx } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { chain, DEFAULT_TITLE_FIELD, NO_DATA_LABEL, TRANSFORM_PROP_REGEXP } from '@shared/constants';
import {
  Y_AXIS_LABEL_DISTANCE,
  MOBILE_DEVICES_REGEXP,
  RANGE_MARGIN,
  DISPLAYABLE_PIN_TYPES,
  COMBO_MARGIN_OFFSET,
  C3_SVG_SELECTOR,
  NVD3_SVG_SELECTOR,
  NVD3_SVG_WRAP_SELECTOR,
  COMBO_PIN_MARGIN,
  RTEOffSet,
  ChartsOffSet,
  SingleViewOffSet,
  NVD3DefaultOffSet,
  TRANSFORM_SCALE_COEF,
} from './chart.constants';
import { AppState } from '../../../../reducers';
import { getPresentationPlans } from '../../../../components/presentation/presentation.selectors';
import { Global } from '@shared/services';
import {
  CareerPlan,
  ChartData,
  ChartOptions,
  ChartRange,
  ChartTooltipValues,
  ChartType,
  ConfigPlanOrganisationData,
  DataTarget,
  PlanMetadata,
  PointData,
  PointPosition,
  TotalPinChart,
} from '@core/model';
import { CALCULATED_DATA_TARGET, ChartTypes, PinTotalTypes } from '@core/enums';
import { isDefined, pixels } from '@core/utils';
import { WINDOW_TOKEN } from '@core/constant';
import { toggleNavbar } from 'src/app/common/shared/components/assurance-navbar/navbar.constants';

@Injectable()
export class ChartService {
  constructor(private store: Store<AppState>, private global: Global, @Inject(WINDOW_TOKEN) private window: Window) {}

  offsetXDistinction: number;
  chartWrapBoundaries: DOMRect;

  private dataItem: { [key in ChartType]?: (data: ChartData[]) => ChartData[] } = {
    [ChartTypes.multiBarChart]: this.normalizeStackedData,
    [ChartTypes.lineChart]: data => data,
    [ChartTypes.lineChartStepAfter]: data => data,
    [ChartTypes.stackedAreaChart]: this.normalizeStackedData,
    [ChartTypes.comboChart]: data => data,
    [ChartTypes.pie]: data => data,
    [ChartTypes.donut]: data => data,
    [ChartTypes.clusteredBarChart]: data => data,
  };

  private pinTypes: { [key in typeof DISPLAYABLE_PIN_TYPES[number]]: (item: ChartData) => Observable<string> } = {
    productComparePin: item =>
      this.getPlanMetadataById(item.planId).pipe(maprx(metadata => metadata && metadata.product_name)),
    metricComparePin: item => {
      const titleField = this.global.getMetricsTitleFieldName();
      const config = this.global.getMetricConfigByKey(item.metricId as string);

      return of(config?.[titleField] || config?.[DEFAULT_TITLE_FIELD] || item.metricTitle);
    },
    carrierComparePin: item =>
      this.getPlanMetadataById(item.planId).pipe(maprx(metadata => metadata && metadata.company_name)),
    customPin: item => {
      return of(item.metricTitle);
    },
  };

  applyStyleToPin(options: ChartOptions, selectorAxisX: string, minusDistance = 0): void {
    const chartPinLine = d3.select(`.${options.id} .chart-pin-line`);
    const chartPinPointSelection = d3.select(`.${options.id} .chart-pin-point`);
    const chartPinPoint = chartPinPointSelection[0][0] as HTMLElement;
    const axisXLine = d3.select(selectorAxisX)[0][0] as HTMLElement;

    if (axisXLine && chartPinPoint) {
      const distance =
        axisXLine.getBoundingClientRect().top -
        chartPinPoint.getBoundingClientRect().top -
        pixels(chartPinPointSelection.style('height'));

      if (distance) {
        const pinHeight = this.checkTransformProperty()
          ? (distance - minusDistance) / TRANSFORM_SCALE_COEF
          : distance - minusDistance;
        chartPinLine.style('height', `${pinHeight}px`);
      }
    }
  }

  getOffsetValue(id: string): number {
    switch (true) {
      case id.includes('single_policy'):
        return SingleViewOffSet;
      case id.includes('retirement_shortfall'):
        return RTEOffSet;
      case id.includes('custom_visualization'):
        return ChartsOffSet;
      default:
        return NVD3DefaultOffSet;
    }
  }

  getShareableLink(): string {
    return `${location.origin}/shared-presentation/${this.global.getPresentation?.shareableToken}?linkSource=fromPDF`;
  }

  setOffsetXDistinction(id: string, isComboChart = false) {
    setTimeout(() => {
      this.offsetXDistinction = this.getBoundaries(id, isComboChart ? C3_SVG_SELECTOR : NVD3_SVG_SELECTOR)?.left;
      this.chartWrapBoundaries = this.getBoundaries(id, NVD3_SVG_WRAP_SELECTOR);
    }, 200);
  }

  normalizeData(data: ChartData[], chartType: ChartType = ChartTypes.lineChart) {
    if (!data) {
      return;
    }

    return this.dataItem[chartType](data);
  }

  getPositionByValue(value: number, containerSelector: string, isPdf: boolean): PointPosition {
    let isBarChart = false;
    let isComboChart = false;
    let barWidth = 0;
    let points: NodeList = this.getLinePoints(containerSelector);

    if (!points || !points.length) {
      points = this.getBarPoints(containerSelector);
      isBarChart = true;
    }

    if (!points || !points.length) {
      points = this.getComboPoints(containerSelector);
      isComboChart = true;
      isBarChart = false;
    }

    const pointValue = { value, x: 0 };
    let validPoint: DOMRect;
    this.offsetXDistinction = this.getBoundaries(
      containerSelector,
      isComboChart ? C3_SVG_SELECTOR : NVD3_SVG_SELECTOR
    ).left;

    const isTransformedChart = this.checkTransformProperty();

    if (isTransformedChart) {
      this.offsetXDistinction /= TRANSFORM_SCALE_COEF;
    }

    let elementIndex = 0;

    for (let i = 0; i < points.length; i++) {
      const point = points[i] as SVGRectElement; // Node
      const data: any = d3.select(point).datum(); // Datum
      const x = data[0] ? data[0].x : data.x;

      if (x === value) {
        validPoint = point.getBoundingClientRect();

        if (isBarChart) {
          barWidth = point.width.baseVal.value / 2;
        }

        elementIndex = i;
        break;
      }
    }

    if (validPoint && isComboChart) {
      const xTick = this.setComboChartXPosition(containerSelector, elementIndex);

      pointValue.x = Math.floor(
        xTick - this.offsetXDistinction - Y_AXIS_LABEL_DISTANCE + (isPdf ? COMBO_PIN_MARGIN : 0)
      );
    } else if (validPoint) {
      const adjustedLeftPosition = isTransformedChart ? validPoint.left / TRANSFORM_SCALE_COEF : validPoint.left;

      pointValue.x = Math.floor(adjustedLeftPosition - this.offsetXDistinction - Y_AXIS_LABEL_DISTANCE + barWidth);
    }

    return pointValue;
  }

  private setComboChartXPosition(containerSelector: string, elementIndex: number): number {
    const tickElements = d3.selectAll(`#c3Chart-${containerSelector} .c3-axis.c3-axis-x g.tick line`);
    const elementRect = (tickElements[0][elementIndex] as HTMLElement)?.getBoundingClientRect()?.x;

    return this.checkTransformProperty() ? elementRect / TRANSFORM_SCALE_COEF : elementRect;
  }

  getValueByPointPosition(position: number, containerSelector: string): PointPosition {
    let isBarChart = false;
    let isComboChart = false;
    let points: NodeListOf<SVGRectElement> = this.getLinePoints(containerSelector);

    if (!points || !points.length) {
      points = this.getBarPoints(containerSelector);
      isBarChart = true;
    }

    if (!points || !points.length) {
      points = this.getComboPoints(containerSelector);
      isComboChart = true;
      isBarChart = false;
    }

    return isBarChart || isComboChart
      ? this.getBarChartData(points, position, containerSelector, isComboChart)
      : this.getLineChartData(points, position);
  }

  getTooltipDataByPinPosition(
    chartData: ChartData[],
    x: number,
    pinType: string,
    chartType: ChartType,
    tooltipTotalOptions: TotalPinChart,
    metricDataSource: DataTarget
  ): ChartTooltipValues<Observable<string>>[] {
    let yTotal = 0;
    const source = metricDataSource === CALCULATED_DATA_TARGET.metadata ? 'meta' : 'data';

    const tooltipDataByPinPosition = chartData.map((item: ChartData) => {
      const y = this.getYFromMetricData(item, x, source) ?? this.getYByX(x, item.values);
      yTotal += isNil(y) || isNaN(y) ? 0 : Number(y);

      const formattedY = y === null ? y : item.isCustomMetric ? Number(y).toFixed(2) : `${y}`;

      return {
        color: item.color,
        y: formattedY,
        isPercentYAxis: item.isPercentYAxis,
        isWholeNumberYAxis: item.isWholeNumberYAxis,
        title$: this.pinTypes[pinType](item),
        noValueLabel: NO_DATA_LABEL,
        hideMetric: item.hideMetric,
        isCustomMetric: item.isCustomMetric,
      };
    });

    if (
      chartType !== ChartTypes.lineChart &&
      tooltipTotalOptions.pinTotalType &&
      tooltipTotalOptions.pinTotalType !== PinTotalTypes.noTotal
    ) {
      const total = {
        isTotal: true,
        color: tooltipTotalOptions.pinTotalColor,
        y: yTotal.toString(),
        isPercentYAxis: chartData[0]?.isPercentYAxis,
        isWholeNumberYAxis: chartData[0]?.isWholeNumberYAxis,
        title$: of(tooltipTotalOptions.pinTotalLabel),
        noValueLabel: NO_DATA_LABEL,
        hideMetric: false,
        isCustomMetric: chartData[0]?.isCustomMetric,
      };
      tooltipTotalOptions.pinTotalType === PinTotalTypes.bottomTotal
        ? tooltipDataByPinPosition.push(total)
        : tooltipDataByPinPosition.unshift(total);
    }

    return tooltipDataByPinPosition;
  }

  hoverBar(value: number, containerSelector: string, className: string, isPDF: boolean, isCombo = false) {
    const points = isCombo ? this.getComboBarHighlightPoints(containerSelector) : this.getBarPoints(containerSelector);
    points.forEach(point => point.classList.remove(className));

    for (let i = 0; i < points.length; i++) {
      const point = points[i] as SVGRectElement;
      const datum: any = d3.select(point).datum(); //Datum

      if (datum.x === value) {
        point.classList.add(className);
        // We have to add fill-opacity property for PDF generation.
        isPDF && (point.style.fillOpacity = '1');
      }
    }
  }

  resetHoverBar(containerSelector: string, className: string, isPDF?: boolean) {
    const points: NodeListOf<SVGRectElement> = document.querySelectorAll(`.${containerSelector} .${className}`);

    for (let i = 0; i < points.length; i++) {
      points[i].classList.remove(className);
      isPDF && (points[i].style.fillOpacity = '');
    }
  }

  highlightBar(x: number, pageId: string, className: string, isPDF = false, isCombo = false) {
    this.resetHoverBar(pageId, className, isPDF);
    this.hoverBar(x, pageId, className, isPDF, isCombo);
  }

  getYByX(x: number, values: { x: number; y: number }[]): number | null {
    const point = find(values, { x });

    return point ? point.y : null;
  }

  getDataRange(data: ChartData[]): ChartRange {
    let x: number[] = [];
    let y: number[] = [];

    data.map((item: ChartData) => {
      if (!item.disable) {
        x = union(x, map(item.values, 'x')).map(Number);
        y = union(y, map(item.values, 'y')).map(Number);
      }
    });

    return {
      minX: min(x),
      maxX: max(x),
      minY: min(y),
      maxY: max(y),
    };
  }

  getForceY(data: ChartData[]): number[] {
    const range = this.getDataRange(data);
    const maxY = Number(range.maxY === 0) || RANGE_MARGIN * range.maxY;
    const minY = range.minY < 0 ? range.minY : 0;

    return [minY, maxY];
  }

  isMobileDevice(): boolean {
    return MOBILE_DEVICES_REGEXP.test(navigator.userAgent);
  }

  checkTransformProperty(checkStyles = false): boolean {
    if (this.window.innerWidth >= toggleNavbar) {
      return false;
    }

    const element = document.querySelector('.main-content');
    const style = element && this.window.getComputedStyle(element);
    const transform = style?.transform || style?.webkitTransform;

    if (
      (transform && transform === 'matrix(1.5, 0, 0, 1.5, 0, 0)' && !checkStyles) ||
      (style && style.getPropertyValue('zoom') === '1.5')
    ) {
      return true;
    }

    if (checkStyles) {
      const styleTags = Array.from(document.querySelectorAll('style'));

      return styleTags.some(styleTag => TRANSFORM_PROP_REGEXP.test(styleTag.textContent));
    }

    return false;
  }

  drawUnlimitedLine(chart: any, data: ChartData[], isPdf: boolean): void {
    const found = data.find(
      (item: ChartData) => item.isTotalLTCBalance && item.isTotalLTCBalanceUnlimited && !item.disable
    );

    if (!found) {
      return;
    }

    const svg = d3.select(chart.element).select('svg');
    const chartArea = svg.select('.c3-zoom-rect');
    const width = parseInt(chartArea.style('width'), 10);

    if (data.length === 1) {
      svg.selectAll('.c3-axis-y .tick').style('display', 'none');
    }

    svg
      .select('.c3-chart')
      .append('g')
      .attr('class', 'labels-lines')
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', isPdf ? 110 : 50)
      .attr('y2', isPdf ? 110 : 50)
      .style('stroke', found.colorBase)
      .attr('stroke-width', '4');

    svg
      .select('.c3-axis-y')
      .append('rect')
      .attr('x', -70)
      .attr('y', isPdf ? 100 : 40)
      .attr('width', 70)
      .attr('height', 20)
      .style('fill', '#fff');

    svg
      .select('.c3-axis-y')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('class', 'unlimited-text')
      .attr('x', -35)
      .attr('y', isPdf ? 113 : 53)
      .attr('style', `font-size: 11px; font-weight: bold; stroke-width:1px; fill:${found.colorBase}`)
      .text('UNLIMITED');
  }

  private getLineChartData(points: NodeListOf<SVGRectElement>, position: number): PointPosition {
    let value: number;
    let x: number;

    for (let i = 0; i < points.length; i++) {
      const point: SVGRectElement = points[i];
      const bounds: DOMRect = point.getBoundingClientRect();

      if (this.chartWrapBoundaries.left < position && position < Math.ceil(bounds.right)) {
        const datum: any = d3.select(point).datum(); // Datum
        const data = datum[0] ? datum[0].x : datum.x;
        value = isNil(value) || data < value ? data : value;
        x = Math.floor(bounds.left - this.offsetXDistinction);
      }
    }

    return { value, x, isBarChart: false };
  }

  private getBarChartData(
    points: NodeListOf<SVGRectElement>,
    position: number,
    containerSelector: string,
    isComboChart = false
  ): PointPosition {
    let value: number;
    let x: number;
    let barWidth = 0;

    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const bounds: DOMRect = point.getBoundingClientRect();

      const leftBounderies = this.checkTransformProperty() ? bounds.left / TRANSFORM_SCALE_COEF : bounds.left;

      if (
        this.chartWrapBoundaries?.left < position &&
        Math.floor(leftBounderies) < position &&
        Math.floor(leftBounderies) + bounds.width > position
      ) {
        const datum: any = d3.select(point).datum(); // Datum
        value = datum[0] ? datum[0].x : datum.x;

        if (isComboChart) {
          const xTick = this.setComboChartXPosition(containerSelector, i);
          x = Math.floor(xTick - this.offsetXDistinction + COMBO_MARGIN_OFFSET);
        } else {
          barWidth = point.width.baseVal.value / 2;
          x = Math.floor(leftBounderies - this.offsetXDistinction + barWidth);
        }

        break;
      }
    }

    return { value, x, isBarChart: true, isComboChart };
  }

  private getBoundaries(containerSelector: string, classSelector: string) {
    const chartWrapArea: SVGAElement = document.querySelector(`.${containerSelector} ${classSelector}`);

    return chartWrapArea
      ? chartWrapArea.getBoundingClientRect()
      : ({
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          x: 0,
          y: 0,
          height: 0,
          width: 0,
        } as DOMRect);
  }

  private getPlanMetadataById(planId: number): Observable<PlanMetadata> {
    return this.store.pipe(
      select(getPresentationPlans),
      maprx((plans: CareerPlan[]) => {
        const plan = find(plans, { id: planId });

        return plan ? plan.configjson.metadata : null;
      })
    );
  }

  private getYFromMetricData(item: ChartData, x: number, source: string): number | null {
    const metricConfig = item.isCustomMetric
      ? this.global.getCustomMetricConfigByKey(item.metricId, item?.productIndex)
      : this.global.getMetricConfigByKey(item.metricId, source);

    const plan = this.global.getCurrentCarrierPlans?.find(plan => plan.id === item.planId)?.configjson[source];

    if (!metricConfig || !plan) {
      return null;
    }

    //TODO: planDataKeyX could be undefined
    const indexOfX = isDefined(item.planDataKeyX)
      ? plan[item.planDataKeyX]?.findIndex(value => parseInt(value, 10) === x)
      : -1;

    const y =
      indexOfX !== -1
        ? item.isCustomMetric
          ? metricConfig[indexOfX]
          : plan[(metricConfig as ConfigPlanOrganisationData).db][indexOfX]
        : null;

    return y;
  }

  private normalizeStackedData(data: ChartData[]): ChartData[] {
    const ages = chain(data)
      .flatMap((item: ChartData) => {
        return item.values.map((value: PointData) => value.x);
      })
      .uniq()
      .sortBy()
      .value();
    data.forEach((item: ChartData) => {
      const values: PointData[] = [];
      ages.forEach((age: number) => {
        const y = find(item.values, { x: age });
        values.push({ x: age, y: y ? y.y : 0 });
      });
      item.values = values;
    });

    return data;
  }

  private getBarPoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(`.${containerSelector} .nv-groups > .nv-group > .nv-bar`);
  }

  private getLinePoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(`.${containerSelector} .nv-groups > .nv-group > .nv-point`);
  }

  private getComboPoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(`.${containerSelector} .c3 .c3-event-rect`);
  }

  private getComboBarHighlightPoints(containerSelector: string): NodeListOf<SVGRectElement> {
    return document.querySelectorAll(`.${containerSelector} .c3 .c3-chart-bars .c3-bar`);
  }
}
