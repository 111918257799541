<nav
  [ngClass]="{ collapsed: collapsedNavbar }"
  class="side-bar col-3 a-app-left-sidebar-background-color"
  *ngIf="!preview && configs && plansOptions?.dndList?.length"
  [ngStyle]="sidebarOffsetStyles"
>
  <div class="spinner-holder" *ngIf="pendingRequests">
    <ac-loader></ac-loader>
  </div>

  <div class="nav-wrapper">
    <section class="plans-nav" *ngIf="plansOptions?.dndList?.length">
      <header class="nav-padding text-uppercase">
        <h6><strong>Products</strong></h6>
      </header>

      <ul
        class="list-unstyled pr-plans"
        [dragulaModel]="plansOptions.dndList"
        [dragula]="plansOptions.navId"
      >
        <li
          *ngFor="let item of plansOptions.dndList; let i = index"
          (click)="selectPlan(item, $event)"
          [ngClass]="{ selected: item.id === selectedPlanId }"
          [class.input-focus]="item.isProductNoteMode"
          class="side-bar-item plan-item item-main a-app-menu-items-color product-item align-items-center justify-content-center a-app-menu-items-color-hovered"
        >
          <div class="nav-icon">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </div>
          <div class="nav-content">
            <span>{{ item.label }}</span>
            <span class="side-bar-item-text" title="{{ item.label }}">
              <!-- if id always number, how to compare with string! -->
              {{ item.id === 'cover-details' ? item.label : item.productName }}
            </span>
            <ensight-note-input
              class="product-note"
              [note]="item.productNote"
              [disabled]="isPresentationLocked"
              [isNavBar]="true"
              (changedNote)="onSaveNote($event, item.id)"
            ></ensight-note-input>
          </div>
        </li>
      </ul>
    </section>

    <section
      class="pages-nav"
      *ngIf="dndList.length && !global.getPresentationMenuState.setup"
    >
      <header class="nav-padding text-uppercase">
        <h6>
          <strong>{{ navName }}</strong>
        </h6>
      </header>

      <ul
        class="list-unstyled"
        [dragulaModel]="dndList"
        [dragula]="listId"
        *ngIf="dragulaIsReady"
      >
        <ng-container *ngIf="endPagesAtStart && endPagesAtStart.length">
          <li
            class="side-bar-item plan-item dragged-disabled item-main a-app-menu-items-color a-app-menu-items-color-hovered"
            *ngFor="let endPage of endPagesAtStart"
            (click)="selectItem(endPage, false, $event)"
            [class.selected]="
              selectedPage.config.uiId === endPage.config.uiId &&
              selectedPage.config.isSalesConcept ===
                endPage.config.isSalesConcept &&
              selectedPage.isEndPage === endPage.isEndPage &&
              ((endPage.parentUiId &&
                selectedPage.parentUiId === endPage.parentUiId) ||
                !endPage.parentUiId)
            "
          >
            <div class="nav-icon opacity-0">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </div>
            <span class="side-bar-item-text" title="{{ endPage.label }}">
              {{ endPage.label }}
            </span>
          </li>
        </ng-container>

        <ng-container *ngFor="let item of dndList">
          <li
            [ngClass]="{
              selected:
                (selectedPage.config.uiId === item.config.uiId &&
                  selectedPage.config.isSalesConcept ===
                    item.config.isSalesConcept &&
                  selectedPage.isEndPage === item.isEndPage &&
                  ((item.parentUiId &&
                    selectedPage.parentUiId === item.parentUiId) ||
                    !item.parentUiId)) ||
                isSelectedPageIncluded(item.config.uiId),
              dragged: isDragged(item.order || item.pageOrder),
              'dragged-disabled':
                item.config.uiId === 'cover' || item.dragDisabled,
              'excluded-item': !item.config.showPreview
            }"
            class="side-bar-item plan-item a-app-menu-items-color-hovered"
          >
            <div
              (click)="selectItem(item, false, $event)"
              [ngClass]="{
                selected:
                  (selectedPage.config.uiId === item.config.uiId &&
                    selectedPage.config.isSalesConcept ===
                      item.config.isSalesConcept &&
                    selectedPage.isEndPage === item.isEndPage &&
                    ((item.parentUiId &&
                      selectedPage.parentUiId === item.parentUiId) ||
                      !item.parentUiId)) ||
                  isSelectedPageIncluded(item.config.uiId),
                'custom-main-color': getLinkedPages(item.config.uiId).length
              }"
              class="item-main a-app-menu-items-color a-app-menu-items-color-hovered"
            >
              <div
                class="nav-icon"
                [class.opacity-0]="
                  (!isDragged(item.order || item.pageOrder) &&
                    item.config.uiId === 'cover') ||
                  item.dragDisabled
                "
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </div>
              <span
                class="side-bar-item-text"
                title="{{ item.label || item.pageName }}"
              >
                {{ item.label || item.pageName }}
              </span>
              <ensight-simple-toggle
                *ngIf="!item.toggleDisabled"
                [loading]="toggleSpinner[item.config.uiId]"
                [readonly]="global.pageLoading || isPresentationLocked"
                [type]="'replace'"
                [disable]="item.isDisabled"
                [checked]="
                  item.config.isParentEnabled ||
                  item.config.showPreview ||
                  !!getIncludedDependentChecked(item.config.uiId)
                "
                (readonlyEvent)="showLockedPresentationMsg()"
                (toggle)="toggleSection(item, $event, true, $event, true)"
              ></ensight-simple-toggle>
            </div>

            <ng-container *ngIf="dependentPages && dependentPages.length">
              <ng-container *ngFor="let page of mergeCustomPages(item)">
                <section
                  [class.selected]="
                    selectedPage.config.uiId === page.config.uiId &&
                    selectedPage.config.isSalesConcept ===
                      page.config.isSalesConcept &&
                    selectedPage.isEndPage === page.isEndPage &&
                    ((page.parentUiId &&
                      selectedPage.parentUiId === page.parentUiId) ||
                      !page.parentUiId)
                  "
                  class="item-custom a-app-sub-menu-items-color a-app-menu-items-color-hovered"
                  (click)="
                    selectItem(
                      page,
                      false,
                      $event,
                      item?.config?.uiId,
                      item?.config?.isSalesConcept
                    )
                  "
                >
                  <span
                    class="side-bar-item-text a-app-menu-items-color"
                    title="{{ getPageLabel(page, item) }}"
                  >
                    {{ getPageLabel(page, item) }}
                  </span>
                  <ensight-simple-toggle
                    *ngIf="!item.toggleDisabled"
                    [loading]="toggleSpinner[item.config.uiId]"
                    [type]="'replace'"
                    [readonly]="global.pageLoading || isPresentationLocked"
                    [disable]="
                      item.isDisabled ||
                      isDependentPagesLocked(item) ||
                      (page.config.isSalesConcept &&
                        isSaleConceptLockedAndEnabled(item)) ||
                      page.isPageLockedAndEnabled
                    "
                    [checked]="
                      !(
                        item.config.showPreview ||
                        !!getIncludedDependentChecked(item.config.uiId)
                      )
                        ? false
                        : isDependentPagesLocked(item) ||
                          (page.config.isSalesConcept &&
                            isSaleConceptLockedAndEnabled(item)) ||
                          page.isPageLockedAndEnabled ||
                          page.showPreview ||
                          page.config.showPreview
                    "
                    (toggle)="toggleCustomPage(page, item.config.uiId, $event)"
                  ></ensight-simple-toggle>
                </section>
              </ng-container>
            </ng-container>
          </li>
        </ng-container>

        <ng-container *ngIf="endPagesAtEnd && endPagesAtEnd.length">
          <li
            class="side-bar-item plan-item dragged-disabled item-main a-app-menu-items-color a-app-menu-items-color-hovered"
            *ngFor="let endPage of endPagesAtEnd"
            (click)="selectItem(endPage, false, $event)"
            [class.selected]="
              selectedPage.config.uiId === endPage.config.uiId &&
              selectedPage.config.isSalesConcept ===
                endPage.config.isSalesConcept &&
              selectedPage.isEndPage === endPage.isEndPage &&
              ((endPage.parentUiId &&
                selectedPage.parentUiId === endPage.parentUiId) ||
                !endPage.parentUiId)
            "
          >
            <div class="nav-icon opacity-0">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </div>
            <span class="side-bar-item-text" title="{{ endPage.label }}">
              {{ endPage.label }}
            </span>
          </li>
        </ng-container>
      </ul>
    </section>

    <ng-template ngFor let-page [ngForOf]="staticPages">
      <ng-template
        *accessControl="{ group: ['add_target_premium_page'] }"
        [ngIf]="
          page.config.uiId === 'target_premium' && page.config.showPreview
        "
      >
        <div class="static-page-item" (click)="selectStaticPage(page)">
          <div
            class="static-page-content a-app-menu-items-color a-app-menu-items-color-hovered"
            [class.selected]="
              selectedPage.config.uiId === page.config.uiId &&
              selectedPage.config.isSalesConcept ===
                page.config.isSalesConcept &&
              selectedPage.isEndPage === page.isEndPage &&
              ((page.parentUiId &&
                selectedPage.parentUiId === page.parentUiId) ||
                !page.parentUiId)
            "
          >
            <div class="icon">%</div>
            <div class="page-name">{{ page.pageName }}</div>
          </div>
          <div class="static-page-message">
            <div>
              <span class="fa fa-exclamation-circle" aria-hidden="true"></span>
              <span class="sr-only">Note:</span>
              Please note: Target premium is not viewable via shared link and
              presentation view.
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>

    <ul
      [hidden]="hideControls"
      class="nav-padding list-unstyled plans-controls"
    >
      <ng-container *ngIf="plansOptions?.dndList?.length">
        <ng-container *accessControl="{ group: ['add_target_premium_page'] }">
          <li *ngIf="isTargetPremiumBtnShown && !isRestrictedToAddPages">
            <button
              class="btn a-app-action-buttons-color pp-action-button-link"
              [disabled]="global.pageLoading || isPresentationLocked"
              (click)="addTargetPremium('target_premium')"
            >
              <span class="fa fa-plus" aria-hidden="true"></span>
              <span class="btn-label">Target Premium</span>
            </button>
          </li>
        </ng-container>

        <ng-container *accessControl="{ group: ['add_cover_letter'] }">
          <li
            *ngIf="
              !isCoverLetterExist &&
              selectedPlanId !== 'cover-details' &&
              !isAnnuityProduct &&
              !isRestrictedToAddPages
            "
          >
            <button
              class="btn a-app-action-buttons-color pp-action-button-link"
              [disabled]="global.pageLoading || isPresentationLocked"
              (click)="openCoverSheetModal()"
            >
              <span class="fa fa-plus" aria-hidden="true"></span>
              <span class="btn-label">Add Coversheet</span>
            </button>
          </li>
        </ng-container>

        <ng-container *accessControl="{ group: ['sales_concepts'] }">
          <li>
            <button
              class="btn a-app-action-buttons-color pp-action-button-link"
              [disabled]="
                global.pageLoading ||
                isPresentationLocked ||
                (isModalAvailable | async) !== true
              "
              (click)="openSalesConceptModal()"
            >
              <span class="fa fa-plus" aria-hidden="true"></span>
              <span class="btn-label">Add Sales Story</span>
            </button>
          </li>
        </ng-container>
      </ng-container>
    </ul>

    <div class="collapse-btn" (click)="collapseNavbar()">
      <a>
        <span
          [hidden]="collapsedNavbar"
          class="fa fa-chevron-left"
          aria-hidden="true"
        ></span>
      </a>
      <a>
        <span
          [hidden]="!collapsedNavbar"
          class="fa fa-chevron-right"
          aria-hidden="true"
        ></span>
      </a>
    </div>
  </div>
</nav>

<aside
  *ngIf="preview && !isHeaderHidden"
  class="sidebar-heading-content a-app-left-sidebar-background-color"
  [class.disabledNav]="disabled"
>
  <div class="logo-block">
    <button
      *ngIf="isNavbarShown"
      (click)="collapseNavbar()"
      class="heading-toggle"
      type="button"
      [disabled]="!isNavbarShown"
      [ngClass]="collapsedNavbar ? 'icon-collapsed' : 'icon-showed'"
    >
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a
      alt="Assurance Logo"
      [ngClass]="{ 'aligned-logo': !isNavbarShown }"
      class="a-app-view-logo"
    ></a>
    <div class="nav-divider"></div>
  </div>

  <div *ngIf="isRotateDeviceModalShown" class="rotate-screen-suggestion">
    <img
      class="rotate-device-icon"
      src="../../../../../assets/icons/rotate-device-icon.svg"
    />
    <div class="rotate-hint-title">
      For the best experience,
      <br />
      please rotate your device
    </div>
    <button class="dismiss-btn" (click)="onDismissModal()">
      <span>Dismiss</span>
    </button>
  </div>

  <div
    *ngIf="
      hasUserPermissionToChangeVersion &&
      versionAddedDate &&
      (selectedPage?.isDependentPage ||
        selectedPage?.isSalesConcept ||
        selectedPage?.config.isSalesConcept) &&
      !isTookByFormula
    "
    class="version-selector"
  >
    <div class="version-details d-md-flex align-items-center">
      <span class="current-version pp-action-button-link text-dark">
        Selected version: {{ currentVersionName }}
      </span>
      <button
        (click)="changePageVersion()"
        class="btn change-version-btn d-flex align-items-center ps-2 a-app-action-buttons-color"
      >
        <span class="icon fa fa-gear"></span>
        <span class="label-date">
          {{ versionAddedDate | date: 'MM/dd/yyyy' }}
        </span>
      </button>
    </div>
    <div class="mobile-version-details d-none" (click)="changePageVersion()">
      Version
    </div>
  </div>

  <div class="navigation-section">
    <se-button
      *ngIf="mergedNavbarPages.length > 1"
      class="secondary"
      size="medium"
      [disabled]="!isNavigationAvailable(navBack)"
      (click)="handlePageNavigation(navBack)"
    >
      <span
        class="btn-icon back-icon"
        [class.disabled]="!isNavigationAvailable(navBack)"
      ></span>
      Previous
      <span class="btn-text">Page</span>
    </se-button>

    <se-button
      *ngIf="mergedNavbarPages.length > 1"
      class="secondary"
      size="medium"
      [disabled]="!isNavigationAvailable(navNext)"
      (click)="handlePageNavigation(navNext)"
    >
      Next
      <span class="btn-text">Page</span>
      <span
        class="btn-icon next-icon"
        [class.disabled]="!isNavigationAvailable(navNext)"
      ></span>
    </se-button>
  </div>

  <div class="action-btn-block">
    <button
      *ngIf="
        isShowSettingsButton || isShowSalesConceptSettings;
        else emptyContainer
      "
      class="btn btn-link pp-action-button-link a-app-action-buttons-color ep-action-link"
      (click)="handleSettingsOpen()"
    >
      <span class="btn-icon settings-icon"></span>
      <span class="action-text">{{ getSettingsLabel() }}</span>
    </button>

    <ng-template #emptyContainer>
      <div class="empty-container" [style.min-width.px]="104"></div>
    </ng-template>

    <a
      (click)="openSharingModal()"
      class="btn btn-link pp-action-button-link float-right a-app-action-buttons-color ep-action-link"
      data-at-id="presentation-buttons-send"
    >
      <span class="btn-icon ensight-icon-send share-link"></span>
      <span class="btn-label">Share</span>
    </a>

    <a
      class="btn btn-link pp-action-button-link a-app-action-buttons-color ep-action-link"
      [class.disabled]="pdfGeneration$ | async"
      (click)="downloadPDFBackend()"
    >
      <span class="btn-icon ensight-icon-out"></span>
      <span class="action-text">Download .PDF</span>
      <div
        class="ep-action-message"
        [class.hidden]="(pdfGeneration$ | async) === false"
      >
        <ep-pdf-loading-message
          message="Your PDF is being generated."
        ></ep-pdf-loading-message>
      </div>
    </a>

    <button
      class="btn btn-link btn-fullscreen pp-action-button-link a-app-action-buttons-color ep-action-link"
      (click)="handleFullScreen()"
    >
      <ng-container *ngIf="!fullScreenIsEnabled(); else exitFullscreen">
        <span class="btn-icon fa fa-arrows-alt fullscreen-icon"></span>
        <span class="action-text">Fullscreen</span>
      </ng-container>
      <ng-template #exitFullscreen>
        <span class="btn-icon fa fa-compress exit-fullscreen-icon"></span>
        <span class="action-text">Exit Fullscreen</span>
      </ng-template>
    </button>

    <div *ngIf="canEditPresentation" class="btn btn-link">
      <button
        class="btn-menu d-flex flex-column align-items-center border-0 a-app-action-buttons-color"
        epClickOutside
        [trigger]="['btn-menu-item', 'btn-menu']"
        (click)="toggleMenu()"
        (close)="clickOutside()"
      >
        <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
      </button>
      <div
        class="menu shared-presentation-actions bg-white shadow-sm d-flex flex-column align-items-center justify-content-center"
        *ngIf="isMenuOpen"
      >
        <button
          *ngIf="canEditPresentation"
          type="button"
          class="btn-menu-item text-left w-100 py-2 px-3 border-0"
          (click)="openSetUp()"
        >
          <img
            src="../../../../../assets/icons/edit-pencil.svg"
            alt="edit pencil icon"
            class="icon pb-1"
          />
          Edit Presentation
        </button>
      </div>
    </div>

    <div *ngIf="!global.isSharedPresentationView()">
      <div class="nav-divider"></div>
      <button
        class="btn a-app-action-buttons-color btn-close"
        data-at-id="presentation-view-close"
        (click)="presentationSetupSelect()"
      >
        &#10005;
      </button>
    </div>
  </div>
</aside>

<nav
  *ngIf="preview && isNavbarShown"
  [ngClass]="{ collapsed: collapsedNavbar }"
  [class.collapsed]="disabled"
  class="preview-sidebar a-app-view-sidebar-background-color col-3"
>
  <div class="spinner-holder" *ngIf="pendingRequests">
    <ac-loader></ac-loader>
  </div>

  <aside class="sidebar-content">
    <div class="header-sidebar">
      <h5>Presentation</h5>
      <button
        type="button"
        *ngIf="isSharedPresentation"
        class="btn btn-close toggle-sidebar"
        (click)="collapseNavbar()"
      >
        &#10005;
      </button>
    </div>
    <ul *ngIf="dndList?.length" class="list-unstyled">
      <ng-container *ngIf="endPagesAtStart && endPagesAtStart.length">
        <li
          *ngFor="let item of endPagesAtStart"
          (click)="dndSelect.emit(item)"
          [class.selected]="
            selectedPage.config.uiId === item.config.uiId &&
            selectedPage.config.isSalesConcept === item.config.isSalesConcept &&
            selectedPage.isEndPage === item.isEndPage &&
            ((item.parentUiId && selectedPage.parentUiId === item.parentUiId) ||
              !item.parentUiId)
          "
        >
          <div class="item-main">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </div>
        </li>
      </ng-container>

      <li
        *ngFor="let item of dndList"
        [ngClass]="{
          selected:
            (selectedPage.config.uiId === item.config.uiId &&
              selectedPage.config.isSalesConcept ===
                item.config.isSalesConcept &&
              selectedPage.isEndPage === item.isEndPage &&
              ((item.parentUiId &&
                selectedPage.parentUiId === item.parentUiId) ||
                !item.parentUiId)) ||
            isSelectedPageIncluded(item.config.uiId)
        }"
      >
        <div
          *ngIf="
            item.config.isParentEnabled ||
            item.config.showPreview ||
            !!getIncludedDependentChecked(item.config.uiId)
          "
          class="item-main"
          (click)="goToPage(item.uiId || item)"
        >
          <div title="{{ item.label || item.pageName }}">
            {{ item.label || item.pageName }}
          </div>
        </div>
        <ng-container *ngIf="dependentPages && dependentPages.length">
          <ng-container *ngFor="let page of mergeCustomPages(item, true)">
            <section
              (click)="
                selectItem(
                  page,
                  false,
                  $event,
                  item?.config?.uiId,
                  item?.config?.isSalesConcept
                )
              "
              *ngIf="
                !(
                  item.config.isParentEnabled ||
                  item.config.showPreview ||
                  !!getIncludedDependentChecked(item.config.uiId)
                )
                  ? false
                  : isDependentPagesLocked(item) ||
                    (page.config.isSalesConcept &&
                      isSaleConceptLockedAndEnabled(item)) ||
                    page.isPageLockedAndEnabled ||
                    page.showPreview ||
                    page.config.showPreview
              "
              [ngClass]="{
                selected:
                  selectedPage.config.uiId === page.config.uiId &&
                  selectedPage.config.isSalesConcept ===
                    page.config.isSalesConcept &&
                  selectedPage.isEndPage === page.isEndPage &&
                  ((page.parentUiId &&
                    selectedPage.parentUiId === page.parentUiId) ||
                    !item.parentUiId)
              }"
              class="item-custom"
            >
              <span
                class="side-bar-item-text"
                title="{{ getPageLabel(page, item) }}"
              >
                {{ getPageLabel(page, item) }}
              </span>
            </section>
          </ng-container>
        </ng-container>
      </li>

      <ng-container *ngIf="endPagesAtEnd && endPagesAtEnd.length">
        <li
          *ngFor="let item of endPagesAtEnd"
          (click)="dndSelect.emit(item)"
          [class.selected]="
            selectedPage.config.uiId === item.config.uiId &&
            selectedPage.config.isSalesConcept === item.config.isSalesConcept &&
            selectedPage.isEndPage === item.isEndPage &&
            ((item.parentUiId && selectedPage.parentUiId === item.parentUiId) ||
              !item.parentUiId)
          "
        >
          <div class="item-main">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </div>
        </li>
      </ng-container>
    </ul>
  </aside>
</nav>

<ensight-info-modal></ensight-info-modal>
