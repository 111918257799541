import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { ButtonsModule } from '@se/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserListComponent],
  imports: [CommonModule, ButtonsModule, FormsModule],
  exports: [UserListComponent],
})
export class UserListModule {}
