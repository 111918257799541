import { createSelector } from '@ngrx/store';
import { AppState } from '../../reducers';
import { State as PresentationState } from './presentation.reducer';

export const getPresentationReducer = (state: AppState): PresentationState => state.presentation;

export const getRules = createSelector(getPresentationReducer, (state: PresentationState) => state.rules.data);

export const getSharedToken = createSelector(getPresentationReducer, (state: PresentationState) => state.sharedToken);

export const getRulesLoading = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.rules.loading
);

export const getHeaderDisabled = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.header.disabled
);

export const getXAxisSource = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.xAxisSource.value
);

export const getPresentationPlans = createSelector(getPresentationReducer, (state: PresentationState) => state.plans);

export const getPresentationData = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.presentation.data
);

export const getPresentationConfigPending = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.configs.loading
);

export const getPresentationId = createSelector(getPresentationData, state => state.id);

export const selectPresentationNavigation = createSelector(getPresentationReducer, state => state.navigation);
