import { Injectable, ElementRef } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { forkJoin, throwError, combineLatest, Observable, Subscriber, of, BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { Store, select } from '@ngrx/store';

import { ASSURANCE_ADMIN_ID, DEFAULT_TITLE_FIELD, defaultPlanType } from '@shared/constants';
import { AppState } from '../../../reducers';
import { APIService } from '@shared/services';
import {
  presentationConfigsSettingPending,
  presentationConfigsSettingSuccess,
  presentationPlansUpdateSuccess,
} from '../../../components/presentation/presentation.actions';
import { getPresentationPlans } from '../../../components/presentation/presentation.selectors';
import { CustomPagesConfig, CustomPageValues, EligibleProductTypes, PageConfig } from '@shared/models';
import {
  getDependentPagesConfig,
  getSelectedPage,
  getSelectedPageId,
} from '../../../components/presentation/setup/setup.selectors';
import { IAMService, UserService } from '@assurance/um-services';
import { eiqCase } from '@core/constant';
import { getPermittedConfigs, getPresentationConfigs } from '../../../components/presentation/redux/configs/selectors';
import {
  PRESENTATION_CONFIGS_SESSION_STORAGE_KEY,
  PRESENTATION_SETUP_ROUTER_REGEXP,
  PRESENTATION_VIEW_ROUTER_REGEXP,
  PRODUCTS_SESSION_STORAGE_KEY,
} from '../../../components/presentation/setup/setup.constant';
import { PresentationMenu } from '@shared/models';
import { applyDiff } from 'recursive-diff';
import { CareerPlan, ConfigPlanOrganisation, ConfigPlanOrganisationData } from '@core/model';
import { LocalStorageService } from '@core/service';
import { PermissionKeyEnum } from '@core/enums';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class Global {
  public colorScheme = [
    { color: '#006bb7', free: true },
    { color: '#00c972', free: true },
    { color: '#fc5716', free: true },
    { color: '#9c27b0', free: true },
    { color: '#57F900', free: true },
  ];
  // User Google Analytic Hash
  public userIdHash: string;
  // Themes list
  public themesList: any[];
  public confirmAgreement: boolean;
  public currentTheme: any;
  public presentationSettings: any = {};
  public selectedPlanId: number;
  public selectedSinglePlanId: any;
  public metadata: any = {
    default: {
      guaranteed: {
        full: 'Guaranteed',
        short: 'Grd.',
      },
      non_guaranteed: {
        full: 'Non-Guaranteed',
        short: 'Non-Grd.',
      },
    },
    vul: {
      guaranteed: {
        full: '0% Gross, Guar. Chg.',
        short: '0%Grs GC',
      },
      non_guaranteed: {
        full: 'Assumed %, Curr. Chg.',
        short: 'Asm% CurC',
      },
    },
    annuity: {
      guaranteed: {
        full: 'Guaranteed',
        short: 'Grd.',
      },
      non_guaranteed: {
        full: 'Non-Guaranteed',
        short: 'Non-Grd.',
      },
      historical: {
        full: '- Historical',
        short: 'Hist',
      },
      hypotheticalRate: {
        full: '- Hypothetical Rate',
        short: 'Hypo',
      },
      hypotheticalGross: {
        full: '- Hypothetical Gross 0%',
        short: 'Hypo 0%',
      },
      hypotheticalNegative: {
        full: '- Hypothetical Gross -4%',
        short: 'Hypo -4%',
      },
    },
  };
  public isPresentationView = false;
  public pageLoading = false;
  public chartRendering = false;
  public presentationMenu = new PresentationMenu();

  private plansConfig: ConfigPlanOrganisation;
  private calculatedData: { [metricKey: string]: number[] }[] = [];
  private currentCarrierPlans: CareerPlan[];
  private isDefaultTheme: boolean;
  private activePresentationId: number;
  private activeComponent: string;
  private presentation: any;
  private dispatchEvents: any;
  private viewPageTitleInner = 'Assurance';
  private uploadedCsvFile: any = {};
  private hasUnsavedChanges = new BehaviorSubject<boolean>(false);

  get viewPageTitle(): string {
    return this.viewPageTitleInner;
  }

  set viewPageTitle(value: string) {
    this.viewPageTitleInner = value;
  }

  set setActivePresentationId(value: number) {
    this.activePresentationId = value;
  }

  get getActivePresentationId(): number {
    return this.activePresentationId;
  }

  set setActiveComponent(value: string) {
    this.activeComponent = value;
  }

  get getActiveComponent() {
    return this.activeComponent;
  }

  set setPresentation(value: any) {
    this.presentation = value;
  }
  get getPresentation() {
    return this.presentation;
  }

  set setPlansConfig(config) {
    this.plansConfig = config;
  }

  get getPlansConfig(): ConfigPlanOrganisation {
    return this.plansConfig;
  }

  set setCalculatedData(data) {
    this.calculatedData = data;
  }

  get getPresentationInfo() {
    const plan = _.head(_.sortBy(this.getCurrentCarrierPlans, ['order']));

    return {
      clientName: this.getPresentation.clientname,
      productName: _.get(plan, 'configjson.metadata.product_name', ''),
    };
  }

  set setDispatchEvents(value: any) {
    this.dispatchEvents = value;
  }

  get getDispatchEvents() {
    return this.dispatchEvents;
  }

  set setIsDefaultTheme(value: boolean) {
    this.isDefaultTheme = value;
  }

  get getIsDefaultTheme() {
    return this.isDefaultTheme;
  }

  set setCurrentCarrierPlans(value: any) {
    this.currentCarrierPlans = value;
  }

  get getCurrentCarrierPlans(): CareerPlan[] {
    return this.currentCarrierPlans;
  }

  set setPresentationSettings(value: any) {
    this.presentationSettings = value;
  }

  get getPresentationSettings() {
    return this.presentationSettings;
  }

  set setUploadedCsvFile(value: any) {
    this.uploadedCsvFile = value;
  }

  get getUploadedCsvFile() {
    return this.uploadedCsvFile;
  }

  get getPresentationMenuState() {
    return this.presentationMenu;
  }

  constructor(
    private apiService: APIService,
    private store: Store<AppState>,
    private iamService: IAMService,
    private router: Router,
    private userService: UserService,
    private localStorage: LocalStorageService,
    private titleService: Title
  ) {}

  setBrowserTabTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  public getMetricsTitleFieldName(): string {
    const productTypeName = this.getPresentation.productType;

    return productTypeName && productTypeName !== defaultPlanType ? productTypeName.toLowerCase() : DEFAULT_TITLE_FIELD;
  }

  public isPresentationViewRoute() {
    return this.router.url.search(PRESENTATION_VIEW_ROUTER_REGEXP) !== -1;
  }

  public isPresentationSetupRoute() {
    return this.router.url.search(PRESENTATION_SETUP_ROUTER_REGEXP) !== -1;
  }

  public getMetricConfigByKey(key: string | number, source = 'data'): ConfigPlanOrganisationData {
    return this.getPlansConfig[source].find(config => config.db === key);
  }

  public getCustomMetricConfigByKey(key: string | number, productIndex: string | number = 0): number[] {
    const metricData = this.calculatedData?.[productIndex]?.[key] ?? [];

    return metricData?.length ? metricData : null;
  }

  public getTitleByType(text: string) {
    if (!text) {
      return text;
    }

    const grdRexExp = /guaranteed/gi;
    const nonGrdRexExp = /non-guaranteed/gi;

    if (!this.getPresentation || !this.getPresentation.metadata) {
      return text;
    }

    const metadata = this.getPresentation.metadata;
    const newHtml = text.match(nonGrdRexExp)
      ? text.replace(nonGrdRexExp, metadata.non_guaranteed.full)
      : text.replace(grdRexExp, metadata.guaranteed.full);

    return this.clearBases(newHtml);
  }

  public clearBases(html: string): any {
    const basis = /basis/gi;

    return this.getPresentation.productType === 'VUL' ? html.replace(basis, '') : html;
  }

  public activatePresentationMenu(activate?: boolean, items?: string[]): PresentationMenu {
    if (_.isArray(items)) {
      items.forEach(menuItem => ((this.presentationMenu as any)[_.camelCase(menuItem)] = activate ? false : true));
    } else {
      Object.keys(this.presentationMenu).forEach(
        menuItem => ((this.presentationMenu as any)[menuItem] = activate ? false : true)
      );
    }

    return this.presentationMenu;
  }

  public filterEndPages(endPages: CustomPageValues[], isDownloadPDF?: boolean): Observable<CustomPageValues[]> {
    return combineLatest([
      this.store.pipe(select(getPresentationPlans)),
      this.store.pipe(select(this.isSharedPresentation() ? getPresentationConfigs : getPermittedConfigs)),
      this.store.pipe(select(getDependentPagesConfig)),
    ]).pipe(
      map(([plans, configs, data]) => this.getFilteredEndPages(endPages, plans, configs, data?.configs, isDownloadPDF))
    );
  }

  private getFilteredEndPages(
    endPages: CustomPageValues[],
    plans: CareerPlan[],
    configs: PageConfig[],
    customPagesConfigs: CustomPagesConfig[],
    isDownloadPDF: boolean
  ): CustomPageValues[] {
    const productTypes = plans.map(el => el.configjson.metadata.product_type);

    return endPages
      .filter(page => this.checkOnProductType(page, productTypes))
      .filter(page => {
        if (isDownloadPDF && page.hideOnPDF) {
          return false;
        }

        if (page.carrierUiIds && page.carrierUiIds.length) {
          return plans.some(
            plan =>
              page.carrierUiIds.includes(plan.configjson.metadata.carrier_code as string) &&
              this.isPlanSelected(plan, configs, customPagesConfigs)
          );
        }

        return true;
      });
  }

  setHasUnsavedChanges(hasChanges: boolean): void {
    this.hasUnsavedChanges.next(hasChanges);
  }

  getHasUnsavedChanges(): boolean {
    return this.hasUnsavedChanges.getValue();
  }

  public getEndPagesByPosition(endPages: CustomPageValues[] = []): Record<string, CustomPageValues[]> {
    const endPagesAtStart = [];
    const endPagesAtEnd = [];

    endPages.forEach((page: CustomPageValues) => {
      page.customFields?.isPageAtStartOfPresentation ? endPagesAtStart.push(page) : endPagesAtEnd.push(page);
    });

    endPagesAtStart.sort((a, b) => a.orderRank - b.orderRank);
    endPagesAtEnd.sort((a, b) => a.orderRank - b.orderRank);

    return {
      endPagesAtStart,
      endPagesAtEnd,
    };
  }

  private checkOnProductType(page: CustomPageValues, productTypes: string[]): boolean {
    if (!page.customFields?.eligibleProductsActive) {
      return true;
    } else {
      const types = page.customFields?.eligibleProductTypes.reduce((acc: string[], item: EligibleProductTypes) => {
        return [...acc, item.label, item.productType];
      }, []);

      return productTypes.some((type: string) => types.includes(type));
    }
  }

  private isPlanSelected(plan: CareerPlan, configs: PageConfig[], customPagesConfigs: CustomPagesConfig[]): boolean {
    return configs?.some(
      (config: PageConfig) =>
        config.config.uiId !== 'cover' &&
        config.config.uiId !== 'target_premium' &&
        (config.config.showPreview || this.checkIfDependentPageEnabled(config, customPagesConfigs)) &&
        (config.config.selectedPlanId === plan.id ||
          config.config.carrierPlanId === plan.id ||
          config.config.activePlans?.find(activePlan => activePlan.carrierPlanId === plan.id && activePlan.isShown))
    );
  }

  private checkIfDependentPageEnabled(config: PageConfig, customPagesConfigs: CustomPagesConfig[]): boolean {
    return (
      config.config.isSalesConcept &&
      customPagesConfigs
        ?.find((customPageConfig: CustomPagesConfig) => customPageConfig.uiId === config.config.uiId)
        ?.pages.some(page => page.isSelected)
    );
  }

  public getPageConfig(presentationId = this.activePresentationId, updateConfig = true, view = false) {
    const presentationType = _.get(this, 'getPresentation.productType');
    const plansPromise = new Promise<void>((resolve, reject) => {
      const products = JSON.parse(sessionStorage.getItem(PRODUCTS_SESSION_STORAGE_KEY));

      (products?.length && view ? of({ data: products }) : this.apiService.getCareerPlans(presentationId)).subscribe(
        response => {
          this.setCurrentCarrierPlans = response.data;
          this.store.dispatch(
            presentationPlansUpdateSuccess({
              plans: _.cloneDeep(response.data),
            })
          );
          resolve();
        },
        error => {
          reject(error);

          return throwError(`${error.status} - ${error.statusText}`);
        }
      );
    });
    const configPromise = new Promise((resolve, reject) => {
      if (updateConfig) {
        this.store.dispatch(presentationConfigsSettingPending());
      }

      this.apiService.getSetupPageConfig(presentationId, presentationType).subscribe(
        res => {
          const configs = this.getConfiguration(res.data.configs);

          if (updateConfig) {
            this.setIRRSettings(configs);
            this.store.dispatch(
              presentationConfigsSettingSuccess({
                data: configs,
              })
            );
          }

          resolve(configs);
        },
        error => {
          reject(error);

          return throwError(`${error.status} - ${error.statusText}`);
        }
      );
    });

    return forkJoin([plansPromise, configPromise]).pipe(
      map((result: any) => {
        return result[1];
      })
    );
  }

  public getConfiguration(configs: any) {
    // When we generate PDF from presentation which was opened via shared link
    // we should store temporary configuration in PRESENTATION_CONFIGS_SESSION_STORAGE_KEY.
    // This option should be used only for PDF Generator (we set this option in runtime).
    const sessionStorageConfigDiff = sessionStorage.getItem(PRESENTATION_CONFIGS_SESSION_STORAGE_KEY);

    if (sessionStorageConfigDiff) {
      return applyDiff(JSON.parse(JSON.stringify(configs)), JSON.parse(sessionStorageConfigDiff));
    }

    return configs;
  }

  public replaceSymbols(str: string): string {
    const regExp = /(\/)|(\+)|(\[)|(\])|(\^)|(\-)|(\.)|(\\)|(\*)|(\?)|(\{)|(\})|(\$)|(\=)|(\!)|(\()|(\))|(\&)/g;
    const matches = str.match(regExp);

    if (matches) {
      matches.map(item => {
        const regE = new RegExp(`\\${item}`, 'g');
        str = str.replace(regE, `\\${item}`);
      });
    }

    return str;
  }

  public setIRRSettings(configs: any): void {
    if (configs.length) {
      const presentationConfig: any = this.getPresentationSettings || {};
      presentationConfig.irrSettings = {
        bottomIRR: configs[0]?.config.bottomIrr,
        topIRR: configs[0]?.config.topIrr,
      };
      this.presentationSettings.irrSettings = presentationConfig.irrSettings;
    }
  }

  // UTILS
  public interpolateText(template: string, params: string[]): string {
    if (Array.isArray(params) && params.length) {
      for (let i = 0, len = params.length; i < len; i++) {
        template = template.replace(`{${i}}`, params[i]);
      }
    }

    return template;
  }

  public setSharedToken(route: ActivatedRouteSnapshot) {
    const token = route.firstChild.paramMap.get('token');

    if (token) {
      sessionStorage.setItem('shared-token', token);
    }
  }

  public isSharedPresentation() {
    return location.href.includes('shared-presentation');
  }

  public isSharedPresentationView() {
    const href = location.href;

    return href.includes('shared-presentation') && href.includes('/view/');
  }

  public isPresentationExportPdfRoute(): boolean {
    return location.href.includes('/export/pdf');
  }

  public getSharedToken() {
    return sessionStorage.getItem('shared-token');
  }

  checkIfTemplateDistributed(currentAgencyId: number, currentAgencyGroups: number[], presentation: any) {
    const groupsDistributedToValues = Array.isArray(presentation.groups_distributed_to)
      ? this.convertToString(presentation.groups_distributed_to)
      : presentation.groups_distributed_to;
    const organizationDistributedToValues = Array.isArray(presentation.organizations_distributed_to)
      ? this.convertToString(presentation.organizations_distributed_to)
      : presentation.organizations_distributed_to;

    const isGroupDistribution =
      groupsDistributedToValues &&
      !!_.intersection(currentAgencyGroups, groupsDistributedToValues.split(',').map(Number)).length;

    return (
      currentAgencyId !== ASSURANCE_ADMIN_ID &&
      presentation.agencyId !== currentAgencyId &&
      presentation.sharedWith !== currentAgencyId &&
      ((organizationDistributedToValues && organizationDistributedToValues.split(',').includes(`${currentAgencyId}`)) ||
        isGroupDistribution)
    );
  }

  public isCreatedByUserInCurrentOrganization(presentationAgencyId: number): boolean {
    return this.userService.organization.id === presentationAgencyId;
  }

  public isCreatedByCurrentUser(presentationUserId: number): boolean {
    return presentationUserId === this.userService.user.id;
  }

  public isCreatedByUserInChildOrganization(presentationAgencyId: number): boolean {
    return this.userService.organization.allChildren.includes(presentationAgencyId);
  }

  public isDistributedToChildOrganization(organizations_distributed_to: number[]): boolean {
    return this.userService.organization.allChildren.some(childId => organizations_distributed_to.includes(childId));
  }

  public isEditRestricted(presentationUserId: number, presentationAgencyId: number): boolean {
    let editDisabled = false;

    if (this.isCreatedByCurrentUser(presentationUserId)) {
      editDisabled =
        !this.iamService.hasUserAccess('VIEW_OWN_AND_DISTRIBUTED_TEMPLATES_EP') ||
        !this.iamService.hasUserAccess('EDIT_OWN_TEMPLATE_EP');
    } else if (this.isCreatedByUserInCurrentOrganization(presentationAgencyId)) {
      editDisabled =
        !this.iamService.hasUserAccess('VIEW_ORGANIZATION_TEMPLATE_EP') ||
        !this.iamService.hasUserAccess('EDIT_ORGANIZATION_TEMPLATE_EP');
    } else if (this.isCreatedByUserInChildOrganization(presentationAgencyId)) {
      editDisabled =
        !this.iamService.hasUserAccess('VIEW_CHILD_ORGANIZATION_TEMPLATE_EP') ||
        !this.iamService.hasUserAccess('EDIT_CHILD_ORGANIZATION_TEMPLATE_EP');
    }

    return editDisabled;
  }

  public waitImages(templateRef: ElementRef): Observable<void> {
    return new Observable((observer: Subscriber<void>): (() => void) => {
      if (!templateRef) {
        observer.next();
        observer.complete();

        return;
      }

      const images = (templateRef.nativeElement as HTMLElement).querySelectorAll('img');

      let imagesRemaining = images.length;

      if (!imagesRemaining) {
        observer.next();
        observer.complete();

        return;
      }

      function validateCompletion(): void {
        --imagesRemaining;

        if (!imagesRemaining) {
          observer.next();
          observer.complete();

          return;
        }
      }

      for (let index = 0, len = images.length; index < len; index++) {
        const image = images[index];

        if (image.complete) {
          validateCompletion();
        } else {
          image.onload = validateCompletion;
          image.onerror = validateCompletion;
        }
      }

      return (): void => null;
    });
  }

  getSelectedPageName(): Observable<string> {
    return combineLatest([
      this.store.pipe(select(getSelectedPageId)),
      this.store.pipe(select(getSelectedPage)),
      this.store.pipe(select(getPresentationConfigs)),
    ]).pipe(
      map(([selectedPageId, selectedPage, configs]) => {
        if (selectedPageId === selectedPage?.config.uiId) {
          return selectedPage.label ?? selectedPage.pageName;
        }

        return configs.find(config => config.config.uiId === selectedPageId)?.pageName ?? '';
      }),
      first()
    );
  }

  public leavePresentationView(): void {
    const returnUrl = sessionStorage.getItem('case_view_page_url');
    const canEditSharedCase = this.iamService.hasUserAccess(PermissionKeyEnum.edit_shared_case);
    const isPermissionForEIQFlow = this.presentation?.isDistributed && returnUrl && !canEditSharedCase;

    if (
      (returnUrl &&
        this.iamService.hasGroupAccess('case_centric_view') &&
        !this.iamService.hasUserAccess('PRSNT_EDIT') &&
        !this.presentation?.isDistributed) ||
      isPermissionForEIQFlow
    ) {
      this.redirectToReturnUrl(returnUrl);
    } else {
      this.router.navigate(['/presentation', this.getActivePresentationId, 'setup']);
    }
  }

  public leaveImportFlow(): void {
    const returnUrl = sessionStorage.getItem('case_view_page_url');

    if (returnUrl) {
      this.redirectToReturnUrl(returnUrl);

      return;
    }

    const eiqUrlHost = this.localStorage.getNotJSONData('eiqUrlHost');

    if (eiqUrlHost) {
      this.redirectToReturnUrl(eiqUrlHost);

      return;
    }

    this.router.navigate(['home']);
  }

  public redirectToReturnUrl(returnUrl: string): void {
    if (this.validateUrl(returnUrl)) {
      sessionStorage.removeItem('case_view_page_url');
      window.location.href = returnUrl;
    } else {
      console.warn(`The passed url is not valid: ${returnUrl}`);
    }
  }
  private validateUrl(url: string): boolean {
    try {
      const parsedUrl = new URL(url);
      const ensightHost = this.localStorage.getNotJSONData('domainName');
      const eiqUrlHost = this.localStorage.getNotJSONData('eiqUrlHost');
      const trustedDomains = [ensightHost, eiqUrlHost];

      if (trustedDomains.includes(parsedUrl.origin) && url.startsWith('https:')) {
        return true;
      }

      return false;
    } catch (e) {
      console.warn(e);

      return false;
    }
  }

  public generateEiqCaseUrl(id: number): string {
    const eiqUrlHost = this.localStorage.getNotJSONData('eiqUrlHost');

    return eiqUrlHost && id ? `${eiqUrlHost}${eiqCase}/${id}` : '';
  }

  public PDFLinkURL(planId: number, hash: string): string {
    return hash
      ? `${this.localStorage.getNotJSONData('apiHost')}/api/carrier-plan-data/${planId}/pdf-illustration/${hash}`
      : null;
  }

  public newProductPDFLinkURL(planId: number, transactionUUID: string): string {
    return `${localStorage.getItem(
      'apiHost'
    )}/AssuranceAPI/pending-exports/${transactionUUID}/pdf-illustration/${planId}`;
  }

  private convertToString(distributedToArray: string[]): string | null {
    return distributedToArray.length ? distributedToArray.join(',') : null;
  }
}
