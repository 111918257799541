import { Injectable } from '@angular/core';

import { ExtendedPlaceholderMetadata } from '@core/model';
import { ImageInfo } from '@shared/models';
import { LocalStorageService } from '@core/service';
import { customImage } from '@core/constant';

@Injectable()
export class ImageHandlerService {
  constructor(private localStorage: LocalStorageService) {}

  getImageObjects(placeholder: ExtendedPlaceholderMetadata, selectedImage: string): ImageInfo[] {
    return placeholder.filesLinks.map(link => {
      const imageName = link.split('/').pop();

      return {
        name: imageName,
        url: this.buildImageUrl(link),
        nativeUrl: link,
        upload: false,
        selected: selectedImage === imageName,
        delete: false,
        loading: true,
        type: null,
      };
    });
  }

  buildImageUrl(link: string): string {
    return link ? `${this.localStorage.getNotJSONData('apiHost')}${customImage}${link}` : '';
  }

  getImageLink(filesLinks: string[], configSelectedImage: string, defaultSelectedImage: string): string {
    let fileLink = filesLinks.find(fileLink => fileLink.split('/').pop() === configSelectedImage);

    if (!fileLink) {
      fileLink = filesLinks.find(fileLink => fileLink.split('/').pop() === defaultSelectedImage);
    }

    return fileLink;
  }
}
