import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppState, ensightApp, IframeActions, IFrameMessage } from '@ensight/shim-app';
import { AppName } from '@ensight/shim-app/src/utils/types';

@Injectable()
export class IFrameCommunicationService {
  public isInitialized = false;
  private messageSubject = new Subject<IFrameMessage>();
  private message$ = this.messageSubject.asObservable();

  constructor() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data?.appName && (event.data?.appName as AppName) !== 'EPV') {
        this.messageSubject.next(event.data);
      }
    });
  }

  getActionStream(action: IframeActions): Observable<IFrameMessage> {
    return this.message$.pipe(
      filter(message => {
        if (action === IframeActions.initApp) {
          this.isInitialized = true;

          return message.payload?.action === action;
        }

        return this.isInitialized && message.payload?.action === action;
      })
    );
  }

  sendResponse(action: IframeActions, state?: Partial<AppState>): void {
    if (this.isInitialized) {
      this.sendMessage({
        appName: 'EPV',
        payload: { action },
        state,
      });
    }
  }

  sendMessage(message: any): void {
    ensightApp.updateState(message);
  }
}
