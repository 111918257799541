export const iconsMapping = {
  added: 'fa-arrow-circle-up',
  clipboard: 'fa-arrow-circle-up',
  changed: 'fa-pencil',
  created: 'fa-plus',
  deleted: 'fa-trash',
  download: 'fa-download',
  edited: 'fa-pencil',
  updated: 'fa-pencil',
  renamed: 'fa-pencil',
  opened: 'fa-arrow-right',
  reverted: 'fa-redo-alt',
  sent: 'fa-arrow-right',
  shared: 'fa-arrow-circle-up',
  email: 'fa-arrow-circle-up',
};

export const eventsMapping = {
  assignee_added: 'Presentation has been assigned to {0}',
  assignee_deleted: 'Presentation assignee has been removed',
  carrier_plan_added: 'Carrier product {0} ({1}) has been added',
  carrier_plan_deleted: 'Carrier product {0} ({1}) has been removed',
  carrier_plan_edited: 'Carrier product {0} ({1}) has been edited',
  carrier_plan_updated: 'Carrier product {0} ({1}) has been updated',
  carrier_plans_deleted: 'Carrier products {0} have been removed',
  cover_letter_added: 'Coversheet has been added',
  cover_letter_deleted: 'Coversheet has been removed',
  cover_letter_edited: 'Coversheet has been edited',
  event_reverted: 'Presentation was reverted to Revision {0}',
  initial_event: 'Initial event',
  drop_ticket_sent: 'Plan {0} has been submitted through drop ticket process.',
  manager_added: 'Manager {0} has been tagged in presentation',
  manager_deleted: 'Manager has been untagged from presentation',
  max_age_changed: 'Presentation maximum age limit has been set to {0}',
  max_age_deleted: 'Presentation maximum age limit has been deleted',
  min_age_changed: 'Presentation minimum age limit has been set to {0}',
  min_age_deleted: 'Presentation minimum age limit has been deleted',
  pdf_illustration_added: "Compliant Illustration file '{0}' has been added to {1} ({2}) carrier product",
  pdf_illustration_edited: "Compliant Illustration file '{0}' has been changed for {1} ({2}) carrier product",
  presentation_created: 'Presentation has been created',
  presentation_link_open_shared: {
    default: 'Shareable link has been opened',
    email: 'Shareable link has been opened{0} from {1}',
    eiqSummaryPDF: 'Shareable link has been opened from Summary PDF',
    Present: 'Shareable link has been opened by Present view',
    fromPDF: 'Shareable link has been opened from Presentation PDF',
    eiqSummary: 'Shareable link has been opened from Product Summary',
  },
  presentation_deleted: 'Presentation has been deleted',
  presentation_download: 'User downloaded presentation',
  pages_download: 'User downloaded pages: {0}',
  presentation_view_opened: 'Presentation View mode has been opened',
  shareable_link_copied_to_clipboard: 'Presentation shareable link has been copied',
  presentation_renamed: 'Presentation has been renamed from {0} to {1}',
  presentation_type_changed: 'Presentation type has been set to "{0}"',
  share_with_deleted: 'Presentation sharing has been stopped',
  shared_with_added: 'Presentation has been shared to {0}',
  presentation_distributed: 'Presentation has been distributed to {0} {1}',
  presentation_copied: 'Presentation has been copied to {0} agencies',
  presentation_distribution_removed: 'Presentation distribution has been removed',
  presentation_shared_by_email: 'The presentation has been shared{0} by email to {1}',
};

export const historyPlansListSearchFields = ['name', 'updated_user', 'updated_by', 'created_by'];

export const GRID_VIEWS_CONFIG = {
  columnDefs: [
    {
      desc: true,
      filtered: true,
      text: 'ID',
      uiId: 'id',
    },
    {
      desc: true,
      filtered: true,
      text: 'Name',
      uiId: 'presentation_name',
    },
    {
      desc: true,
      filtered: true,
      text: 'Created By',
      uiId: 'created_by_name',
    },
    {
      desc: true,
      filtered: true,
      text: 'Updated By',
      uiId: 'updated_by_name',
    },
    {
      desc: true,
      filtered: true,
      text: 'Created On',
      uiId: 'date',
    },
    {
      desc: true,
      filtered: true,
      text: 'Updated On',
      uiId: 'update_date',
    },
  ],
  columnOrderedNames: ['id', 'presentation_name', 'created_by', 'updated_by', 'date', 'update_date'],
  sortField: 'update_date',
};

export const PAGINATION_PAGE_SIZE = 10;
