import { Pipe, PipeTransform } from '@angular/core';
import { CALCULATED_DATA_TARGET } from '@core/enums';
import { DataTypeInfo, Placeholder } from '@core/model';
import { DATA_TYPE_OBJ } from '../constants';

@Pipe({
  name: 'showDataType',
})
export class ShowDataTypePipe implements PipeTransform {
  transform(placeholders: Placeholder[], placeholder: Placeholder): DataTypeInfo | null {
    const index = placeholders.indexOf(placeholder);

    if (index === 0) {
      return this.getDataTypeInfo(placeholder);
    }

    const prevPlaceholder = placeholders[index - 1];

    if (prevPlaceholder.insertType !== placeholder.insertType) {
      return this.getDataTypeInfo(placeholder);
    }

    if (prevPlaceholder.calculatedDataTarget !== placeholder?.calculatedDataTarget) {
      return this.getDataTypeInfo(placeholder);
    }

    return null;
  }

  private getDataTypeInfo(placeholder: Placeholder): DataTypeInfo | null {
    const dataTarget = placeholder?.calculatedDataTarget;

    if (dataTarget === CALCULATED_DATA_TARGET.data || dataTarget === CALCULATED_DATA_TARGET.metadata) {
      return DATA_TYPE_OBJ[dataTarget];
    }

    return null;
  }
}
