<ac-modal-header (close)="onModalClose()">
  <div class="modal-title">
    <ng-container *ngIf="!hasPermissionToShareViaEmail; else sharedMode">
      <span>Share Presentation</span>
    </ng-container>
    <ng-template #sharedMode>
      <span>Share Presentation by email or link</span>
    </ng-template>
  </div>
</ac-modal-header>

<ac-modal-body>
  <ng-container *ngIf="!showSpinner; else loading">
    <div class="modal-body">
      <div
        *ngIf="!hasPermissionToShareViaEmail ; else emailShareBlock"
        [hidden]="!sharedLink"
        class="shared-link-block"
      >
        <input
          #sharedLinkElem
          type="text"
          class="form-control"
          [(ngModel)]="sharedLink"
          readonly
        />
      </div>

      <ng-template #emailShareBlock>
        <div *ngIf="isAlreadyShared" class="container-buttons pb-3">
          <se-button-group
            id="email"
            [buttons]="pageTabs"
            size="medium"
            (onSelectButton)="selectTab($event)"
          ></se-button-group>
        </div>

        <ng-container [ngSwitch]="selectedTab">
          <ng-container *ngSwitchCase="'addUser'">
            <form [formGroup]="sharePresentationForm">
              <div class="form-group">
                <div class="d-flex flex-column position-relative">
                  <label class="label-text">Email</label>
                  <se-form-input
                    #emailInput
                    id="email"
                    formControlName="recipientEmail"
                    placeholder="email"
                  ></se-form-input>
                </div>
              </div>

              <app-user-list
                [users]="receiverUsersList"
                [showRemoveButton]="true"
                (removeUser)="onRemoveUserFromReceivers($event)"
                (roleChange)="onSelectRole($event)"
              ></app-user-list>

              <div class="d-flex flex-column">
                <label class="label-text">Add Note</label>
                <se-textarea
                  id="note"
                  formControlName="shareNote"
                  name="note"
                  placeholder="Note"
                  resize="none"
                  [height]="56"
                  (keyup.enter)="$event.stopPropagation()"
                ></se-textarea>
              </div>
            </form>
          </ng-container>

          <ng-container *ngSwitchCase="'invitedUsers'">
            <app-user-list
              [users]="invitedUsersList"
              [showInviteInfo]="true"
            ></app-user-list>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="table-list-loader">
      <se-circle-loader type="circle-scale"></se-circle-loader>
    </div>
  </ng-template>
</ac-modal-body>

<app-user-list
  *ngIf="filteredUsersList.length && hasPermissionToShareViaEmail && sharePresentationForm.controls.recipientEmail.value"
  [users]="filteredUsersList"
  [isAutocomplete]="true"
  [selectedIndex]="selectedIndex"
  [listPositionTop]="listPositionTop"
  [listPositionLeft]="listPositionLeft"
  [listWidth]="listWidth"
  (selectUser)="selectUser($event)"
></app-user-list>

<ac-modal-footer>
  <ng-container
    *ngIf="selectedTab !== 'invitedUsers'; else invitedUsersPagination"
  >
    <div
      class="d-flex btn-group"
      [ngClass]="{'flex-row-reverse justify-content-start': !hasPermissionToShareViaEmail, 'justify-content-between': hasPermissionToShareViaEmail}"
    >
      <ng-container
        *ngIf="isSendBtnDisabled || !receiverUsersList.length; else secondaryBtn"
      >
        <se-button
          #copyBtn
          class="primary"
          size="medium"
          (click)="copyLinkToClipboard()"
        >
          Copy Link
          <img
            src="../../../../../assets/icons/copy-link-icon.svg"
            alt="Copy link icon"
          />
        </se-button>
      </ng-container>
      <ng-template #secondaryBtn>
        <se-button
          type="button"
          class="secondary"
          size="medium"
          (click)="copyLinkToClipboard()"
        >
          Copy Link
          <img
            src="../../../../../assets/icons/copy-link-icon-blue.svg"
            alt="Copy link icon"
          />
        </se-button>
      </ng-template>

      <div class="action-btn-group btn-group">
        <se-button
          type="button"
          class="secondary"
          size="medium"
          (click)="onModalClose()"
        >
          Cancel
        </se-button>
        <se-button
          *ngIf="hasPermissionToShareViaEmail"
          type="button"
          class="primary"
          size="medium"
          [disabled]="isSendBtnDisabled"
          (click)="onSendPresentationViaEmail()"
        >
          Send
        </se-button>
      </div>
    </div>
  </ng-container>

  <ng-template #invitedUsersPagination>
    <div *ngIf="!showSpinner" class="container-pagination px-3">
      <se-pager
        [options]="paginationOptions"
        [pagesSliceCount]="5"
        (onPageChange)="setPaginationParams($event)"
      ></se-pager>
    </div>
  </ng-template>
</ac-modal-footer>
