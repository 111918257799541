import { Component, ViewChild, ElementRef, OnInit, HostListener, Inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PresentationSharingService } from './presentation-sharing.service';
import { AlertService } from '@se/common';
import { APIService, GAService, Global, Utils } from '@shared/services';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { ShareableLinkResponse, TablePaginationOptions, TableRequestParams } from '@shared/models';
import { IAMService, UserService } from '@assurance/um-services';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { PermissionKeyEnum } from '@core/enums';
import { UserButtons } from './presentation-sharing-contants';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ExtendedInvitedUsersList,
  ReceiverEmails,
  RoleCategory,
  RoleCategoryResponse,
  SendEmailResponse,
  User,
} from './presentation-sharing.interfaces';
import { WINDOW_TOKEN } from '@core/constant';

@UntilDestroy()
@Component({
  selector: 'ensight-presentation-sharing',
  styleUrls: ['presentation-sharing.scss'],
  templateUrl: 'presentation-sharing.html',
  providers: [PresentationSharingService],
})
export class PresentationSharingComponent implements OnInit {
  @ViewChild('sharedLinkElem') sharedLinkElem: ElementRef;
  @ViewChild('emailInput', { read: ElementRef }) emailInput: ElementRef;
  @ViewChild('copyBtn') copyBtn: ElementRef;

  sharedLink: string;
  presentationData: any;
  sharePresentationForm: FormGroup;
  selectedTab: 'addUser' | 'invitedUsers' = 'addUser';
  filteredUsersList: User[] = [];
  receiverUsersList: User[] = [];
  invitedUsersList: ExtendedInvitedUsersList[] = [];
  showSpinner = true;
  hasPermissionToShareViaEmail = false;
  hasPermissionToViewUsers = false;
  hasPermissionToAssignRole = false;
  isAlreadyShared = false;
  isSendBtnDisabled = true;
  hasCheckedAlreadyShared = false;
  pageTabs = UserButtons;

  listPositionTop = 0;
  listPositionLeft = 0;
  listWidth = 0;
  selectedIndex = -1;
  paginationOptions: TablePaginationOptions;

  private presentationId: number;
  private clientNamePlaceholder = '';
  private assignableRoles: RoleCategory[] = [];
  private defaultAssignableRole: RoleCategory | null = null;

  constructor(
    public modal: ModalRef,
    public config: ModalConfig,
    public global: Global,
    private sharingService: PresentationSharingService,
    private userService: UserService,
    private apiService: APIService,
    private iamService: IAMService,
    private gaService: GAService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private utils: Utils,
    @Inject(WINDOW_TOKEN) protected window: Window
  ) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.hasPermissionToShareViaEmail) {
      const target = event.target as HTMLElement;

      if (target.closest('#email')) {
        this.sharePresentationForm.patchValue({ recipientEmail: event.clipboardData.getData('text') });
        this.handlePasteEvent(event);
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;

    if (target.tagName.toLowerCase() === 'input' && target.getAttribute('placeholder') === 'email') {
      const keyActions = {
        ArrowUp: () => this.navigateList('up'),
        ArrowDown: () => this.navigateList('down'),
        Enter: () => this.selectCurrentUser(),
        Escape: () => this.resetSelection(),
      };

      if (event.key in keyActions) {
        event.preventDefault();
        keyActions[event.key]();
      }
    }
  }

  ngOnInit() {
    this.presentationId = this.config.data?.presentationId;
    this.presentationData = this.config.data?.presentationInfo;

    if (this.utils.readCookie('loggedIn')) {
      this.hasPermissionToShareViaEmail = this.iamService.hasUserAccess(PermissionKeyEnum.share_presentation_by_email);
      this.hasPermissionToViewUsers = this.iamService.hasUserAccess(PermissionKeyEnum.view_users_list);
      this.hasPermissionToAssignRole = this.iamService.hasGroupAccess(
        PermissionKeyEnum.allow_to_assing_role_while_sharing
      );
    }

    if (this.hasPermissionToAssignRole) {
      const assignableRolesRule = this.userService.groupRules.find(
        rule => rule.ruleKey === PermissionKeyEnum.allow_to_assing_role_while_sharing
      );

      if (assignableRolesRule) {
        const decodedRoles = this.sharingService.parseAssignableRoles(assignableRolesRule.ruleValue);

        this.apiService
          .getAllRoleCategories()
          .pipe(untilDestroyed(this))
          .subscribe(allCategories => {
            this.assignableRoles = this.sharingService.mapDecodedRolesToAssignable(decodedRoles, allCategories);
            const defaultRule = this.userService.groupRules.find(
              rule => rule.ruleKey === PermissionKeyEnum.default_role_persmission
            );

            if (defaultRule) {
              this.defaultAssignableRole = this.sharingService.mapDefaultRoleToAssignable(
                defaultRule.ruleValue,
                allCategories
              );
            }
          });
      }
    }

    this.fetchSharedToken();

    if (this.hasPermissionToShareViaEmail) {
      this.hasPermissionToViewUsers ? this.watchForRequestParams() : (this.showSpinner = !this.showSpinner);
      this.setFormValues();
    }

    this.setupCopyListener();
  }

  onModalClose(): void {
    this.modal.close({ submitted: false });
  }

  onSendPresentationViaEmail(): void {
    const emails: ReceiverEmails[] = this.receiverUsersList.map(user => ({
      email: user.email,
      userId: user.id || '',
      roleId: (this.hasPermissionToAssignRole && user.roleCategory.find(role => role.selected).roleId) || undefined,
    }));

    this.showSpinner = !this.showSpinner;

    this.sharingService
      .sendShareableLinkViaEmail(
        emails,
        this.sharePresentationForm.value.shareNote,
        this.presentationId,
        this.global.isSharedPresentation()
      )
      .pipe(
        finalize(() => {
          this.showSpinner = !this.showSpinner;
        })
      )
      .subscribe(
        (response: SendEmailResponse) => {
          if (this.config.data?.isDashboard) {
            this.global.setActivePresentationId = this.presentationId;
            this.global.setPresentation = this.presentationData;
          }

          this.gaService.sendPresentationEvent({
            eventAction: 'Shared by email',
          });
          this.modal.close({ submitted: true });

          if (response?.failedEmails?.length) {
            this.handleError('Something went wrong. Please try again.');
          } else if (response.success) {
            this.showSuccessAlert('The email has been successfully sent.');
          }
        },
        error => {
          this.modal.close({ submitted: false });
          this.handleError(error);
        }
      );
  }

  copyLinkToClipboard(): void {
    const clientName = this.clientNamePlaceholder || this.presentationData.clientname;

    this.sharingService
      .copyToClipboard(this.sharedLink, clientName)
      .pipe(
        switchMap(() => {
          this.showSuccessAlert('Link copied.');

          if (!this.global.isSharedPresentation()) {
            return this.apiService.createHistoryEvent(this.presentationId, {
              eventName: 'shareable_link_copied_to_clipboard',
            });
          }

          return of(null);
        }),
        catchError(() => {
          this.handleError('We could not copy the presentation link to clipboard, please do this manually.');

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.copyBtn?.nativeElement?.blur();
      });
  }

  selectTab(event: Record<string, string>): void {
    this.filteredUsersList = [];
    this.selectedTab = event.key as 'addUser' | 'invitedUsers';
    this.pageTabs = this.pageTabs.map(item => ({ ...item, active: event.key === item.key }));
  }

  onRemoveUserFromReceivers(emailToRemove: string): void {
    this.receiverUsersList = this.receiverUsersList.filter(user => user.email !== emailToRemove);
    this.isSendBtnDisabled = !this.receiverUsersList.length || this.receiverUsersList.some(user => user.isInvalidEmail);
  }

  onSelectRole(updatedUser: User): void {
    this.receiverUsersList = this.receiverUsersList.map(user =>
      user.email === updatedUser.email ? updatedUser : user
    );
  }

  selectUser(user: User): void {
    this.filteredUsersList = [];
    this.sharePresentationForm.patchValue({
      recipientEmail: '',
    });
    user.isInvalidEmail = !this.sharingService.isValidEmail(user.email);

    if (this.isEmailUnique(user.email)) {
      if (this.hasPermissionToAssignRole && !user.isInvalidEmail) {
        this.handleUserWithRoleAssignment(user);
      } else {
        this.addUserToReceiverList(user);
      }
    }
  }

  private handleUserWithRoleAssignment(user: User): void {
    // Case 1: When User has returned role category
    if (user.role?.roleCategory) {
      user.roleCategory = [
        {
          ...user.role.roleCategory,
          selected: true,
          roleId: user.role.id,
        },
      ];
      user.isRoleDisabled = true;
      this.addUserToReceiverList(user);

      return;
    }

    // Case 2: Get Role category by email
    this.sharingService
      .getRoleCategoryByEmail(user.email)
      .pipe(
        untilDestroyed(this),
        map(roleCategoryResponse => {
          return this.updateUserRoleCategories(user, roleCategoryResponse, this.assignableRoles);
        }),
        take(1)
      )
      .subscribe(updatedUser => {
        if (this.isEmailUnique(updatedUser.email)) {
          this.addUserToReceiverList(updatedUser);
        }
      });
  }

  private updateUserRoleCategories(
    user: User,
    roleCategoryResponse: RoleCategoryResponse | string,
    assignableRoles: RoleCategory[]
  ): User {
    // Case 2.1: If no category is returned - set default role / 'None'
    if (!roleCategoryResponse) {
      if (this.defaultAssignableRole) {
        const mappedRoles = assignableRoles.map(role => ({
          ...role,
          selected: role.id === this.defaultAssignableRole.id,
        }));

        if (!assignableRoles.some(role => role.id === this.defaultAssignableRole.id)) {
          mappedRoles.push({
            ...this.defaultAssignableRole,
            selected: true,
          });
        }

        return {
          ...user,
          roleCategory: mappedRoles,
          isRoleDisabled: false,
        };
      } else {
        const noneOption: RoleCategory = {
          name: 'None',
          selected: true,
        };

        return {
          ...user,
          roleCategory: [noneOption, ...assignableRoles.map(role => ({ ...role, selected: false }))],
          isRoleDisabled: false,
        };
      }
    }

    // Case 2.2: Role category is returned - set this category and list from permission
    const userRoleCategory: RoleCategory = {
      id: (roleCategoryResponse as RoleCategoryResponse).roleCategoryId,
      name: (roleCategoryResponse as RoleCategoryResponse).roleCategoryName,
      selected: true,
    };

    const existingRoleIndex = assignableRoles.findIndex(role => role.id === userRoleCategory.id);

    return {
      ...user,
      roleCategory:
        existingRoleIndex !== -1
          ? assignableRoles.map((role, index) => ({
              ...role,
              selected:
                index === existingRoleIndex ||
                (this.defaultAssignableRole && role.id === this.defaultAssignableRole.id),
            }))
          : [...assignableRoles, userRoleCategory],
    };
  }

  private addUserToReceiverList(user: User): void {
    this.receiverUsersList = [...this.receiverUsersList, user];
    this.isSendBtnDisabled = this.receiverUsersList.some(user => user.isInvalidEmail);
  }

  setPaginationParams(options: TablePaginationOptions): void {
    this.showSpinner = !this.showSpinner;
    this.sharingService.setTableRequestParams(options);
  }

  private handlePasteEvent(event: ClipboardEvent): void {
    event.preventDefault();
    this.filteredUsersList = [];

    const pastedText = event.clipboardData.getData('text').trim();
    const emails = this.sharingService.extractEmailsFromInput(pastedText);

    this.handleMultipleEmails(emails).subscribe(users => {
      if (emails.length > 1) {
        users.forEach(user => {
          const avatar = this.sharingService.getAvatar(user.email, user.firstName, user.avatarName);

          const updatedUser = {
            ...user,
            avatarUrl: avatar.url,
            avatarInitial: avatar.initial,
          };

          this.selectUser(updatedUser);
        });

        this.sharePresentationForm.patchValue({ recipientEmail: '' });
      } else {
        this.filteredUsersList.push(users[0]);
        this.sharePresentationForm.patchValue({ recipientEmail: emails[0] });
      }
    });
  }

  private handleMultipleEmails(emails: string[]): Observable<User[]> {
    if (!this.hasPermissionToViewUsers) {
      return of(emails.map(email => ({ email, avatarInitial: this.sharingService.getAvatar(email).initial })));
    }

    const requests = emails.map(email =>
      this.sharingService
        .getUsersByEmail(email, true)
        .pipe(
          map(users =>
            users.length ? users : [{ email, avatarInitial: this.sharingService.getAvatar(email).initial }]
          )
        )
    );

    return forkJoin(requests).pipe(
      map(results => results.flat()),
      catchError(() => {
        return of([]);
      })
    );
  }

  private setupCopyListener(): void {
    this.sharedLinkElem?.nativeElement.addEventListener('copy', () => {
      if (this.config.data?.isDashboard) {
        const agencyId = this.userService.organization.id;
        this.sharingService
          .getAgencyNames([agencyId])
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            const name = response.data[0]?.name;
            this.gaService.sendShareableLinkEvent(name, agencyId);
          });
      } else {
        this.gaService.sendShareableLinkEvent(this.userService.organization.name, this.userService.organization.id);
      }

      this.gaService.sendPresentationEvent({ eventAction: 'Copy Shareable Link' });
    });
  }

  private fetchSharedToken(): void {
    if (!this.presentationData.shareableToken && !this.global.isSharedPresentation()) {
      this.sharingService
        .getSharedToken(this.presentationId)
        .pipe(
          untilDestroyed(this),
          catchError((err: any) => {
            this.handleError(`We could not complete your request. ${err}`);

            return of(null);
          }),
          finalize(() => {
            if (!this.hasPermissionToShareViaEmail) {
              this.showSpinner = !this.showSpinner;
            }
          })
        )
        .subscribe((data: ShareableLinkResponse) => {
          if (data) {
            this.sharedLink = this.sharingService.generateSharedLink(data.token);
            this.clientNamePlaceholder = data.clientName2
              ? `${data.clientName} and ${data.clientName2}`
              : data.clientName;
          }
        });
    } else {
      this.sharedLink = this.sharingService.generateSharedLink(this.presentationData.shareableToken);

      if (!this.hasPermissionToShareViaEmail) {
        this.showSpinner = !this.showSpinner;
      }
    }
  }

  private watchForRequestParams(): void {
    this.sharingService
      .watchForRequestParams()
      .pipe(
        switchMap((params: TableRequestParams) =>
          this.sharingService.getExtendedinvitedUsersList(
            this.presentationId,
            this.global.isSharedPresentation(),
            params.PAGE
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(({ data, totalCount }) => {
        this.setInvitedUsersData(data, totalCount);
        this.checkIfAlreadySharedOnce(totalCount);
      });
  }

  private checkIfAlreadySharedOnce(totalCount: number): void {
    if (!this.hasCheckedAlreadyShared) {
      this.hasCheckedAlreadyShared = true;

      if (this.isAlreadyShared) {
        this.prepareTabs(totalCount);
      }
    }
  }

  private setInvitedUsersData(extendedInvitedUsers: ExtendedInvitedUsersList[], totalCount: number): void {
    extendedInvitedUsers = extendedInvitedUsers.map(user => {
      const avatar = this.sharingService.getAvatar(user.receiverEmail, user.firstName, user.avatarName);

      return {
        ...user,
        avatarUrl: avatar.url,
        avatarInitial: avatar.initial,
      };
    });
    this.invitedUsersList = extendedInvitedUsers;
    this.isAlreadyShared = !!this.invitedUsersList.length;
    this.paginationOptions = {
      currentPage: this.sharingService.getPaginationParams().PAGE,
      totalElements: totalCount,
      itemsPerPage: 5,
    };

    this.showSpinner = !this.showSpinner;
  }

  private setFormValues(): void {
    this.sharePresentationForm = this.fb.group({
      recipientEmail: [''],
      shareNote: [''],
    });

    this.sharePresentationForm
      .get('recipientEmail')
      .valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        debounceTime(300),
        filter((value: string) => value.trim() !== ''),
        switchMap((value: string) => {
          this.updatePortalPosition();

          return this.hasPermissionToViewUsers ? this.sharingService.getUsersByEmail(value) : of([]);
        })
      )
      .subscribe((users: User[]) => {
        this.filteredUsersList = this.handleFilteredUsersList(users);
      });
  }

  private handleFilteredUsersList(users: User[]): User[] {
    if (users.length) {
      return users.map(user => {
        const avatar = this.sharingService.getAvatar(user.email, user.firstName, user.avatarName);

        return {
          ...user,
          avatarUrl: avatar.url,
          avatarInitial: avatar.initial,
        };
      });
    }

    const email = this.sharePresentationForm.get('recipientEmail').value;

    return [
      {
        email: email,
        avatarUrl: '',
        avatarInitial: this.sharingService.getAvatar(email).initial,
      },
    ];
  }

  private prepareTabs(totalCount: number): void {
    this.pageTabs = this.pageTabs.map(btn => {
      return {
        ...btn,
        label: btn.key === 'invitedUsers' ? `${btn.label} (${totalCount})` : btn.label,
        disabled: false,
      };
    });
  }

  private updatePortalPosition(): void {
    if (this.emailInput) {
      const { bottom, left, width } = this.emailInput?.nativeElement?.getBoundingClientRect() || {};

      this.listPositionTop = bottom;
      this.listPositionLeft = left;
      this.listWidth = width;
    }
  }

  private navigateList(direction: 'up' | 'down'): void {
    if (!this.filteredUsersList.length) {
      return;
    }

    const directionActions = {
      up: () => {
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.filteredUsersList.length - 1;
      },
      down: () => {
        this.selectedIndex = this.selectedIndex < this.filteredUsersList.length - 1 ? this.selectedIndex + 1 : 0;
      },
    };

    directionActions[direction]();
  }

  private selectCurrentUser(): void {
    if (this.selectedIndex !== -1 && this.selectedIndex <= this.filteredUsersList.length) {
      this.selectUser(this.filteredUsersList[this.selectedIndex]);
      this.resetSelection();
    }
  }

  private resetSelection(): void {
    this.selectedIndex = -1;
    this.filteredUsersList = [];
  }

  private isEmailUnique(email: string): boolean {
    return !this.receiverUsersList.some(existingUser => existingUser.email.toLowerCase() === email.toLowerCase());
  }

  private handleError(message: string): void {
    this.alertService.openAlert({
      type: 'error',
      body: message,
      autoClose: 5000,
    });
  }

  private showSuccessAlert(message: string): void {
    this.alertService.openAlert({
      type: 'success',
      body: message,
      autoClose: 5000,
    });
  }
}
