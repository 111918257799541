import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash-es';

import { APIService, Global, TimeService } from '@shared/services';
import { baseUrl, TimeFormat } from '@core/constant';
import { Presentation, ResponseType } from '@core/model';
import {
  eventsMapping,
  historyPlansListSearchFields,
  iconsMapping,
} from '../../../../modules/history/history.constant';
import { ExtraInfo, HistoryEvent, MappedHistoryEvent } from '@shared/models';
import { RoleCategory } from '@assurance/um-services/public-api';

@Injectable()
export class HistoryService {
  public storage: Record<string, string>;
  private eventsThatNeedRoleCategory = ['presentation_shared_by_email', 'presentation_link_open_shared'];

  constructor(
    private http: HttpClient,
    private global: Global,
    private timeService: TimeService,
    private apiService: APIService
  ) {}

  getEvents(id: number): Observable<HistoryEvent[]> {
    return this.http
      .get<ResponseType<HistoryEvent[]>>(`${baseUrl}presentation/${id}/events/`)
      .pipe(map((res: ResponseType<HistoryEvent[]>) => res.data));
  }

  getEventsWithRoleCategories(id: number): Observable<{
    historyEvents: HistoryEvent[];
    roleCategories: RoleCategory[];
  }> {
    return this.getEvents(id).pipe(
      switchMap((historyEvents: HistoryEvent[]) => {
        const needsRoleCategories = historyEvents.some(event => this.eventsThatNeedRoleCategory.includes(event.name));

        return needsRoleCategories
          ? this.apiService.getAllRoleCategories().pipe(map(roleCategories => ({ historyEvents, roleCategories })))
          : of({ historyEvents, roleCategories: [] });
      })
    );
  }

  prepareHistoryItems(events: HistoryEvent[], roleCategories?: RoleCategory[]): MappedHistoryEvent[] {
    const existedRevisions = [events[events.length - 1].revision_id];

    return events.map((item: HistoryEvent, i: number) => {
      if (i > 0) {
        existedRevisions.push(events[i - 1].revision_id);
      }

      const extraInfo = JSON.parse(item.extra_info);

      if (extraInfo && extraInfo.assignee) {
        extraInfo.assignee = extraInfo.assignee.fullName;
      }

      if (extraInfo && extraInfo.shared_with) {
        extraInfo.shared_with = extraInfo.shared_with.name;
      }

      return {
        actionType: this.getActionType(item.name),
        date: this.timeService.getUTCOffsetFormattedDate(item.update_date, TimeFormat.MMDDYYYYhmma),
        id: item.id,
        name: this.getEventMsg(item.name, extraInfo, roleCategories),
        revertible: existedRevisions.indexOf(events[i].revision_id) === -1,
        seqNum: i + 1,
        updatedBy: item && item.userInfo ? item.userInfo.full_name : 'Unknown User',
      };
    });
  }

  getMappedPresentations(presentations: Presentation[]): Presentation[] {
    return presentations.map((item: Presentation) => {
      return {
        created_by: item.createdByName,
        config_locked: item.config_locked,
        date: item.date,
        id: item.id,
        presentation_name: item.deleted ? `${item.name} Deleted` : item.name,
        update_date: item.update_date,
        updated_by: item.updatedByName,
      };
    });
  }

  getFilteredHistoryEvents(historyEvents: MappedHistoryEvent[], searchPhrase?: string): MappedHistoryEvent[] {
    if (historyEvents && searchPhrase) {
      return historyEvents.filter((item: MappedHistoryEvent) => {
        let searchString: string;

        historyPlansListSearchFields.forEach(field => {
          searchString += item[field] + '|';
        });

        return searchString.toLowerCase().indexOf(searchPhrase.toLowerCase()) !== -1;
      });
    }

    return historyEvents;
  }

  private getActionType(actionKey: string): string {
    return iconsMapping[actionKey.split('_').pop()];
  }

  private getEventMsg(actionKey: string, extraInfo: ExtraInfo, roleCategories?: RoleCategory[]): string {
    let mainMessage = eventsMapping[actionKey];
    const processedExtraInfo = this.processExtraInfo(actionKey, extraInfo);

    if (actionKey === 'presentation_link_open_shared') {
      mainMessage = eventsMapping[actionKey][extraInfo?.linkSource] || eventsMapping[actionKey]['default'];
      const roleName = this.getRoleCategoryName(extraInfo?.roleId, roleCategories);
      processedExtraInfo.unshift(roleName ? ` by ${roleName}` : '');
    }

    if (actionKey === 'presentation_shared_by_email') {
      const roleName = this.getRoleCategoryName(extraInfo?.roleId, roleCategories);
      processedExtraInfo.unshift(roleName ? ` with ${roleName}` : '');
    }

    return this.global.interpolateText(mainMessage, processedExtraInfo);
  }

  private getRoleCategoryName(roleId, roleCategories): string {
    if (!roleId || !roleCategories?.length) {
      return '';
    }

    const roleCategory = roleCategories.find(category => category.roles?.some(role => role.id === roleId));

    return roleCategory ? roleCategory.name : '';
  }

  private processExtraInfo(actionKey: string, extraInfo: ExtraInfo): string[] {
    const emailActions = {
      presentation_link_open_shared: 'email',
      presentation_shared_by_email: 'receiverEmail',
    };

    if (emailActions[actionKey] && extraInfo?.[emailActions[actionKey]]) {
      const email = extraInfo[emailActions[actionKey]];

      return [`<a href="mailto:${email}">${email}</a>`];
    }

    return _.values(extraInfo);
  }
}
